import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import {
	View,
	Text,
	Dimensions,
	Image,
	TouchableOpacity,
	ScrollView,
	Modal,
} from "react-native";
import moment from "moment";
import { APP_COLOURS, StatusColour } from "../APP_VARS";
import { Avatars } from "../APP_VARS";
import WhitePaper from "./WhitePapers/WhitePaper";
import { crudReadUnass, fetchNotices, sendDiscordMessage } from "../CRUDHELPER";
import NewWhitepaper from "./WhitePapers/NewWhitepaper";

export default class NoticeBoardComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showWhitePaper: "",
			notices_: [
				// 				{
				// 					id: "1234",
				// 					title: "Mandatory Policy Change",
				// 					dateCreated: "04/01/2024",
				// 					text: `
				// 			I trust this letter finds each of you all well.
				// 			As our organization continues to evolve and adapt to new challenges, we have recently undergone significant restructuring and experienced an increase in workflow. These changes are vital to our ongoing success and necessitates a corresponding adjustment in our operational approach.

				// 			To foster a more cohesive and efficient work environment, we have decided to implement a mandatory in-office work schedule for all staff members. Effective immediately, our standard working hours will be from 8:00 AM to 5:00 PM, Monday through Friday. This decision was made with careful consideration, recognizing the value of direct collaboration and communication among team members. Working side by side will enhance our ability to monitor performance, streamline our workflow, and strengthen our team dynamics.

				// 			In addition to this change, we are also introducing a mandatory protocol regarding the use of our Project Management Dashboard. It is now required for all employees to begin their day by logging into the dashboard, continuously manage and update tasks throughout the day, and conclude their day with a final update. This tool is essential for tracking our collective progress, managing resources effectively, and ensuring transparency across all projects.

				// 			Please understand that adherence to this system is crucial. Failure to update the Project Management Dashboard consistently will be viewed as a performance issue and may result in formal performance review actions. It is important to recognize that consistent use of the dashboard is not only about individual accountability but also about contributing to the overall efficiency and success of our team.

				// 			We appreciate your understanding and cooperation as we implement these changes. Our goal is to create an environment that maximizes productivity and fosters a strong, collaborative team culture. We are confident that these adjustments will contribute significantly to our shared goals and the overall success of our organization.

				// 			Should you have any questions or concerns regarding these new policies, please feel free to reach out to your manager. We are here to support you through this transition and ensure a smooth adaptation to these new practices.

				// 			Thank you for your continued dedication and hard work. Together, we will continue to achieve great things.
				// `,
				// 					image: "https://cdn.discordapp.com/attachments/1107650389060374551/1192524811168137296/kalel0568_social_media_and_content_creation_team_illustration_p_cd6d0998-5462-4722-aea7-80e8e135afd2.png?ex=65a96447&is=6596ef47&hm=d027ca70d49ec5b3ecf005626261af3cdbf9d372496ac7bbbc1a3b79384d4a03&",
				// 					writtenBy: {
				// 						first_name: "Wesley",
				// 						last_name: "Cheia",
				// 						avatar: 48,
				// 					},
				// 					bgColor: "#090D13",
				// 					mode: "Dark",
				// 				},
				// 				{
				// 					id: "ewfsefv",
				// 					title: "Welcoming 2024 - A Year of Opportunities and Growth!",
				// 					dateCreated: "01/01/2024",
				// 					text: `
				// 			Happy New Year! As we step into 2024, I want to extend my warmest compliments of the season to each one of you. It's a time for new beginnings, and I am thrilled to embark on this journey with a team as dedicated and talented as ours.

				// Reflecting on the past year, we've experienced significant growth as a company. This expansion has not only been a testament to our collective efforts but has also introduced unique challenges that we've navigated together with resilience and adaptability.

				// One of the most notable changes has been the restructuring of our teams, new clients onboarded and the integration of numerous new projects and functions within the business. These changes are pivotal in shaping our path forward and demonstrate our capability to evolve and thrive in a dynamic environment.

				// With growth comes responsibility - Please check for Urgent & pending tasks on the project management dashboard. The dashboard is now available for mobile browsers: https://skrum.tetrice.co.za.
				// There are some limited functionalities but we will continue to make improvements. The iOS and Android apps will be distributed later in the year as our dev teams are inundated with other projects to complete.

				// As some of you may already know, Early in December Wouter was in a horrific accident and has been hospitalised. He is in stable condition but will require allot of your prayers and well wishes. If any of you wish to know his status, please don't hesitate to reach out.

				// Thank you for your ongoing dedication and hard work. Here's to a prosperous and fulfilling year ahead!`,
				// 					image: "https://cdn.discordapp.com/attachments/1107650389060374551/1192544793503486152/kalel0568_social_media_and_content_creation_team_celebrating_ne_dfe9891c-7e84-47b4-ab5d-5bb4ffef4aed.png?ex=65a976e3&is=659701e3&hm=80343a4d548b8df688c1d44c160cb17743d2a53878ab160ac8a4696a950300e7&",

				// 					writtenBy: {
				// 						first_name: "Wesley",
				// 						last_name: "Cheia",
				// 						avatar: 47,
				// 					},
				// 					bgColor: "#090D13",
				// 					mode: "Dark",
				// 				},

				{
					id: "2025",
					title: "Welcoming 2025 - A Year of Opportunity and Growth",
					dateCreated: "01/01/2025",
					text: "Happy New Year, Team!\n\nAs we step into 2025, I want to express my heartfelt gratitude to each of you. After a challenging yet rewarding 2024, where we achieved remarkable milestones and demonstrated immense resilience, we now stand ready to embrace the opportunities ahead.\n\nLast year brought us growth and success but also required extraordinary effort from everyone. Your dedication has been instrumental in onboarding new clients, integrating new projects, and navigating the transformations within our company. Thanks to your commitment, we've laid a strong foundation for what’s to come.\n\n### Changes Ahead\nThis year, we’re starting fresh with a significant move: **we will be changing offices at the end of January**. This is an exciting development that aligns with our plans for growth and improved collaboration. Further details about the new office location and setup will be shared soon.\n\nAs part of this change, please be reminded that **office hours are strictly from 8 AM to 5 PM**, with no exceptions unless otherwise advised. Your adherence to this schedule ensures that we maintain consistency and efficiency across the team.\n\nAdditionally, we now require everyone to submit their **work schedules and leave requests at least one month in advance**. This policy helps us manage workloads effectively and avoid disruptions to project timelines. Please ensure that all submissions are timely and complete.\n\n### Looking Ahead\nIt’s a time for renewed focus, creativity, and collaboration. Let’s continue to push boundaries and deliver exceptional results as we take on the challenges and opportunities of 2025.\n\nThank you for your incredible efforts, your adaptability, and your vision. Together, let’s make 2025 a year of growth, collaboration, and shared success. Here’s to a prosperous and fulfilling year ahead!\n\nWarm regards,\nWesley Cheia\n*Your Fellow Team Member*",
					image: "https://cdn.discordapp.com/attachments/1107650389060374551/1192544793503486152/kalel0568_social_media_and_content_creation_team_celebrating_ne_dfe9891c-7e84-47b4-ab5d-5bb4ffef4aed.png?ex=65a976e3&is=659701e3&hm=80343a4d548b8df688c1d44c160cb17743d2a53878ab160ac8a4696a950300e7&",
					writtenBy: {
						first_name: "Wesley",
						last_name: "Cheia",
						avatar: 47,
					},
					bgColor: "#090D13",
					mode: "Dark",
				},
			],
			notices: [],
		};
	}

	componentDidMount() {
		this.validateSession();
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();

		this.fetchNotices_();
	}

	fetchNotices_ = () => {
		fetchNotices((s, d) => {
			console.log(s, d);
			this.setState({
				notices: d,
			});
		});
	};

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	validateSession = async () => {
		let user_data = await localStorage.getItem("user_data");
		let email = await localStorage.getItem("email");
		let accessToken = await localStorage.getItem("accessToken");
		let refresh_token = await localStorage.getItem("refresh_token");

		if (accessToken !== null && accessToken !== "") {
			await this.setState({
				user_data: JSON.parse(user_data),
				email,
				accessToken,
				refresh_token,
			});
			setTimeout(() => {
				this.setState({
					loading: false,
					authenticating: false,
				});
			}, 1000);
		} else {
			this.props.navigate("/");
			window.location.href = "/";
			localStorage.clear();
		}
	};

	render() {
		return (
			<View
				style={{
					flex: 1,
					width: "100%",
				}}>
				<View style={{ width: "100%" }}>
					<View
						style={{
							marginVertical: 20,
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
							width: "100%",
						}}>
						<Text
							style={{
								fontFamily: "Gotham",
								fontSize: 19,
								color: APP_COLOURS.TEXTCOLOR,
								fontWeight: "800",
								// opacity: 0.5,
								paddingLeft: 5,
							}}>
							Notice board
						</Text>

						<TouchableOpacity
							style={{
								padding: 3,
								borderRadius: 5,

								backgroundColor: APP_COLOURS.BLUE,
								display:
									this.props.user_data?.username ===
									"wesley@tetrice.co.za"
										? "flex"
										: "none",
							}}
							onPress={() =>
								this.setState({
									newNotice: !this.state.newNotice,
								})
							}>
							<View
								style={{
									padding: 4,
									backgroundColor: APP_COLOURS.BLUE,
								}}>
								<Text
									style={{
										fontFamily: "Gotham",
										fontSize: 16,
										color: APP_COLOURS.TEXTCOLOR,
										fontWeight: "700",
									}}>
									+
								</Text>
							</View>
						</TouchableOpacity>
					</View>

					<View style={{ width: "100%" }}>
						{this.state.notices
							.filter((d) =>
								moment(d.expiryDate, "DD/MM/YYYY").isAfter(
									moment(),
								),
							)
							.map((d) => (
								<TouchableOpacity
									key={d.id}
									style={{
										width: "100%",
										borderRadius: 20,
										marginBottom: 10,
										// flexDirection: "row",
										backgroundColor: d.bgColor,
										overflow: "hidden",
										// minHeight: 170,
										height: 300,
									}}
									onPress={() => {
										this.setState({
											showWhitePaper: d,
										});

										sendDiscordMessage(
											`${this.state.user_data.first_name} ${this.state.user_data.last_name}`,
											`[Notice Opened]:
- ${d.title}
`,
											`${window.location.origin}${
												Avatars[
													this.state.user_data.avatar
												].pp
											}`,
											"0QPZYrY6zpAnJXqX9gQl",
										);
									}}>
									<View
										style={{
											// flex: 1,
											width: "100%",
											// padding: 7,
											alignItems: "center",
											justifyContent: "center",
										}}>
										<View
											style={{
												position: "absolute",
												right: -50,
												top: -100,
												opacity: 0.5,
											}}>
											{typeof d.image !== "undefined" &&
											d.image !== null ? (
												<Image
													source={d.image}
													style={{
														width:
															window.innerWidth *
															0.7,
														height: 220,
														resizeMode: "cover",
													}}
												/>
											) : (
												<Image
													source={require(".//WhitePapers/ARTICLEIMAGE.png")}
													style={{
														resizeMode: "cover",
														width:
															window.innerWidth *
															0.8,
														height:
															window.innerWidth *
															0.8,
													}}
												/>
											)}
										</View>

										<View
											style={{
												position: "absolute",
												right: 20,
											}}>
											<Image
												source={require("./WhitePapers/skrum_menu.png")}
												style={{
													width: 100,
													height: 100,
													resizeMode: "cover",
												}}
											/>
										</View>
										<View
											style={{
												flex: 1,
												width: "100%",
												padding: 7,
											}}>
											<View
												style={{
													width: "100%",
													height: "auto",
													padding: 7,
													alignItems: "center",
													borderRadius: 10,
													// minHeight: 70,
													// marginBottom: 10,
													flexDirection: "row",
													borderColor: "#C5C5C5",
													opacity: 0.9,
												}}>
												<Image
													source={
														Avatars[
															parseInt(
																d.writtenBy
																	.avatar,
															)
														].pp
													}
													style={{
														width: 30,
														height: 30,
														borderRadius: 25,
														resizeMode: "contain",
														backgroundColor: "#FFF",
														borderWidth: 1,
														borderColor: "#C5C5C5",
														// shadowColor: "#ECF4FF",
														// shadowOffset: {
														// 	width: 0,
														// 	height: 8,
														// },
														// shadowOpacity: 0.44,
														// shadowRadius: 10.32,

														// elevation: 16,
													}}
												/>
												<View
													style={{
														padding: 5,
														borderRadius: 10,
														alignItems: "center",
														flexDirection: "column",
														flex: 1,
														alignItems:
															"flex-start",
													}}>
													<Text
														style={{
															fontFamily:
																"Gotham",
															fontSize: 12,
															fontWeight: "600",
															color: APP_COLOURS.WHITE,
														}}>
														{d.writtenBy.first_name}{" "}
														{d.writtenBy.last_name}
													</Text>
												</View>
											</View>
											<View
												style={{
													// flex: 1,
													padding: 5,
													marginLeft: 9,
													width: "100%",
												}}>
												<Text
													style={{
														fontFamily: "Gotham",
														fontSize: 16,
														color:
															d.mode === "Dark"
																? APP_COLOURS.TEXTCOLOR
																: APP_COLOURS.BG3,
														fontWeight: "300",
														width: "80%",
														flexWrap: "wrap",
													}}>
													{d.title}
												</Text>

												<Text
													style={{
														fontFamily: "Gotham",
														fontSize: 9,
														color:
															d.mode === "Dark"
																? APP_COLOURS.TEXTCOLOR
																: APP_COLOURS.BG3,
														fontWeight: "300",
													}}>
													{moment(
														d.dateCreated,
														"DD/MM/YYYY",
													).fromNow()}
												</Text>
												{/* <Text
												style={{
													fontFamily: "Gotham",
													fontSize: 11,
													color:
														d.mode === "Dark"
															? APP_COLOURS.TEXTCOLOR
															: APP_COLOURS.BG3,
													fontWeight: "300",
													maxHeight: 60,
													width: "70%",
													flexWrap: "wrap",
												}}>
												{d?.text?.slice(0, 300)}
											</Text> */}
												<Text
													style={{
														fontSize: 9,
														color:
															d.mode === "Dark"
																? APP_COLOURS.TEXTCOLOR
																: APP_COLOURS.BG3,
														fontFamily: "Gotham",
														fontWeight: "300",
														// marginTop: 10,
														// textAlign: "justify",
													}}>
													<div
														dangerouslySetInnerHTML={{
															__html: d.html?.slice(
																0,
																300,
															),
														}}
													/>
												</Text>
												{/* <View
												style={{
													position: "absolute",
													right: 0,
													bottom: 0,
													opacity: 0.5,
												}}>
												<Image
													source={require("./WhitePapers/fade.png")}
													style={{
														width: window.innerWidth,
														height: 100,
														resizeMode: "cover",
													}}
												/>
											</View> */}
											</View>

											{/* <View
												style={{
													flex: 1,
													padding: 5,
													display: this
														.state.hover
														? "flex"
														: "none",
													marginLeft: 9,
												}}>
												<Text
													style={{
														fontFamily:
															"Gotham",
														fontSize: 12,
														color:
															d.mode ===
															"Dark"
																? APP_COLOURS.TEXTCOLOR
																: APP_COLOURS.BG3,
														fontWeight:
															"900",
													}}>
													tetrice
												</Text>
											</View> */}
										</View>
									</View>
									<View
										style={{
											position: "absolute",
											right: -20,
											bottom: -2,
										}}>
										<Image
											source={require("./WhitePapers/fade.png")}
											style={{
												width: window.innerWidth,
												height: 100,
												resizeMode: "cover",
											}}
										/>
									</View>
								</TouchableOpacity>
							))}
					</View>
				</View>
				{this.state.showWhitePaper !== "" ? (
					<Modal
						animationType='fade'
						transparent={true}
						visible={true}>
						<View
							style={{
								// backgroundColor: APP_COLOURS.BG2,
								// backgroundColor: "#000",
								flex: 1,
								alignItems: "center",
							}}>
							<View
								style={{
									width: "100%",
									// maxWidth: 600,
									padding: 10,
									borderTopLeftRadius: 20,
									backgroundColor: "#000",
								}}>
								<TouchableOpacity
									onPress={() =>
										this.setState({
											showWhitePaper: "",
										})
									}>
									<Image
										source={require("../../assets/images/close.png")}
										style={{
											width: 25,
											height: 25,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>
							</View>
							<WhitePaper data={this.state.showWhitePaper} />
						</View>
					</Modal>
				) : null}

				{this.state.newNotice ? (
					<Modal
						animationType='fade'
						transparent={true}
						visible={true}>
						<View
							style={{
								backgroundColor: APP_COLOURS.BG2,
								flex: 1,
							}}>
							<View
								style={{
									width: "100%",

									padding: 10,
									borderTopLeftRadius: 20,
									backgroundColor: "#000",
								}}>
								<TouchableOpacity
									onPress={() =>
										this.setState({
											newNotice: false,
										})
									}>
									<Image
										source={require("../../assets/images/close.png")}
										style={{
											width: 25,
											height: 25,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>
							</View>
							<View
								style={{
									flex: 1,
								}}>
								<NewWhitepaper
									close={() => {
										this.setState({
											newNotice: false,
										});
										this.fetchNotices_();
									}}
									user_data={JSON.parse(
										localStorage.getItem("user_data"),
									)}
									bgColor={"#090D13"}
									mode='Dark'
								/>
							</View>
						</View>
					</Modal>
				) : null}
			</View>
		);
	}
}
