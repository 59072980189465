import React, { useState, useEffect } from "react";
import { View, ActivityIndicator, ScrollView, Modal } from "react-native";
import { APP_COLOURS, Avatars } from "../../APP_VARS";
import UsersSelectList from "../UsersSelectList";
import { crudReadUnass, crudUpdate, crudCreate } from "../../CRUDHELPER";
import moment from "moment";

const ClientOverview = ({
	projectData,
	tasks,
	descriptionData,
	updateProject,
}) => {
	const [overviews, setOverviews] = useState([]);
	const [selectedOverview, setSelectedOverview] = useState(null);
	const [overviewGenerated, setOverviewGenerated] = useState(false);
	const [showClientModal, setShowClientModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [milestones, setMilestones] = useState([]);
	const [messages, setMessages] = useState([]);
	const [description, setDescription] = useState("");
	const [loading, setLoading] = useState(true);
	const [showEmailModal, setShowEmailModal] = useState(false);
	const [clients, setClients] = useState(projectData?.clients || []);
	const [clientIds, setClientIds] = useState(projectData?.client_ids || []);

	const [reports, setReports] = useState([]);
	const [showReportModal, setShowReportModal] = useState(false);

	const getreports = () => {
		crudReadUnass(
			"projects_reports",
			{
				field: "project_id",
				action: "==",
				value: projectData.id,
			},
			(status, data) => {
				if (status === 200) {
					setReports(data);
				}
			},
		);
	};

	useEffect(() => {
		getreports();
	}, [projectData?.id]);

	useEffect(() => {
		const fetchMilestones = async () => {
			try {
				const fetchedMilestones = await crudReadUnass(
					"projects_milestones",
					{
						field: "project_id",
						action: "==",
						value: projectData.id,
					},
					(status, data) => {
						if (status === 200) {
							setMilestones(data);
						}
					},
				);
			} catch (error) {
				console.error("Error fetching milestones:", error);
			}
		};

		if (projectData?.id) {
			fetchMilestones();
		}
	}, [projectData?.id]);

	useEffect(() => {
		const fetchChats = () => {
			crudReadUnass(
				"projects_timeline",
				{
					field: "project_id",
					action: "==",
					value: projectData.id,
				},
				async (status, d) => {
					if (status === 200) {
						setMessages(d);
					}
				},
			);
		};

		fetchChats();
	}, [projectData.id]);

	useEffect(() => {
		function getLargeHtmlData(geturl, next) {
			let url =
				"https://workflow.tetrice.co.za/webhook/59af9ff2-5530-45b9-b4d8-fb9631c555fb";
			const requestOptions = {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					url: geturl,
				}),
			};
			fetch(url, requestOptions)
				.then((response) => {
					if (!response.ok) {
						throw new Error("Failed to fetch data");
					}
					return response.json();
				})
				.then((html) => {
					console.log(html);
					return next(
						typeof html?.data !== "undefined" ? html?.data : "",
					);
				})
				.catch((error) => {
					console.log(error);
				});
		}

		setLoading(true);

		console.log("descriptionData");
		if (descriptionData?.htmlData?.htmlFileUrl) {
			getLargeHtmlData(descriptionData.htmlData.htmlFileUrl, (d) => {
				setDescription(d);
			});
		} else {
			setDescription(descriptionData);
		}

		Promise.all([
			new Promise((resolve) => {
				if (!projectData?.id) resolve();
				crudReadUnass(
					"projects_milestones",
					{
						field: "project_id",
						action: "==",
						value: projectData?.id,
					},
					(status) => resolve(),
				);
			}),
			new Promise((resolve) => {
				if (!projectData?.id) resolve();
				crudReadUnass(
					"projects_timeline",
					{
						field: "project_id",
						action: "==",
						value: projectData?.id,
					},
					(status) => resolve(),
				);
			}),
		]).finally(() => {
			setLoading(false);
		});
	}, [projectData?.id, descriptionData?.htmlData?.htmlFileUrl]);

	useEffect(() => {
		setClients(projectData?.clients || []);
		setClientIds(projectData?.client_ids || []);
	}, [projectData]);

	const handleGenerateOverview = async () => {
		setIsLoading(true);
		const obj = {
			date: new Date().toISOString(),
			content: projectData,
			tasks: tasks.map((t) => ({
				id: t.id,
				title: t.title,
				description: t.description,
				status: t.status,
				due_date: t.due_date,
				milestone_id: t.milestone_id,
				project_id: t.project_id,
				user_id: t.user_id,
				created_at: t.created_at,
				updated_at: t.updated_at,
			})),
			milestoneData: milestones,
			description: description,
			messages: messages,
		};
		console.log(obj);
		// return;
		try {
			const response = await fetch(
				"https://workflow.tetrice.co.za/webhook/ad1a50d6-fedc-4838-8751-5767eb328443",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(obj),
				},
			);
			const data = await response.json();

			const htmlContent = data?.html || "";

			const newOverview = {
				id: Date.now(),
				date: new Date().toISOString().split("T")[0],
				content: htmlContent,
				roadmap: data?.roadmap,
			};

			setOverviews((prevOverviews) => [...prevOverviews, newOverview]);
			setOverviewGenerated(newOverview);
		} catch (error) {
			console.error("Error fetching overview:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleAddClients = async (newClients, newClientIds) => {
		// Create updated arrays with new clients
		const updatedClients = [...newClients];
		const updatedClientIds = [...newClientIds];

		try {
			// Update the project in the database
			await updateProject({
				id: projectData.id || projectData._id,
				clients: updatedClients,
				client_ids: updatedClientIds,
			});

			// Update local state
			setClients(updatedClients);
			setClientIds(updatedClientIds);
			setShowClientModal(false);
		} catch (error) {
			console.error("Error updating clients:", error);
		}
	};

	const handleSendEmail = async (emailAddresses) => {
		try {
			// Get the HTML content from the overview container
			const overviewContainer =
				document.getElementById("overview-container");
			const htmlContent = overviewContainer
				? overviewContainer.innerHTML
				: "";

			const response = await fetch(
				"https://workflow.tetrice.co.za/webhook/09b394c2-8626-4eef-8ae9-c76515ef860e",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						recipients: emailAddresses,
						content: htmlContent, // Using the actual HTML from the container
						subject: `Project Overview - ${projectData.title} - ${overviewGenerated.date}`,
						roadmap: overviewGenerated.roadmap,
					}),
				},
			);

			if (response.ok) {
				crudCreate(
					"projects_reports",
					{
						project_id: projectData.id,
						overview: htmlContent,
						date: selectedOverview.date,
						roadmap: selectedOverview.roadmap,
						subject: `Project Overview - ${projectData.title} - ${selectedOverview.date}`,
						recipients: emailAddresses,
					},
					() => {
						setOverviewGenerated(null);
						getreports();
					},
				);
				alert("Email sent successfully!");
			} else {
				throw new Error("Failed to send email");
			}
		} catch (error) {
			console.error("Error sending email:", error);
			alert("Failed to send email. Please try again.");
		}
		setShowEmailModal(false);
	};

	return (
		<View
			style={{
				flex: 1,
				flexDirection: "row",
				backgroundColor: APP_COLOURS.OFFWHITE,
				borderRadius: 8,
				display: "flex",
			}}>
			<div
				style={{
					width: 300,
					borderRight: `1px solid ${APP_COLOURS.BG2}`,
					padding: 16,
				}}>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						marginBottom: 16,
					}}>
					<span style={{ fontSize: 18, fontWeight: 600 }}>
						Clients
					</span>
					<button
						onClick={() => setShowClientModal(true)}
						style={{
							backgroundColor: APP_COLOURS.BG2,
							padding: 6,
							borderRadius: 4,
							border: "none",
							cursor: "pointer",
						}}>
						<span style={{ color: "#FFF", fontSize: 12 }}>
							+ Add
						</span>
					</button>
				</div>
				<div style={{ marginBottom: 20 }}>
					{clients.map((client) => (
						<div
							key={client.id}
							style={{
								padding: 8,
								backgroundColor: "#f5f5f5",
								borderRadius: 4,
								marginBottom: 8,
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
							}}>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: "8px",
								}}>
								<img
									src={Avatars[parseInt(client.avatar)].pp}
									style={{
										width: 32,
										height: 32,
										borderRadius: "50%",
										objectFit: "cover",
										backgroundColor: "#FFF",
										border: "2px solid #FFF",
									}}
									alt={`${client.first_name}'s avatar`}
								/>
								<div>
									<div style={{ fontWeight: 500 }}>
										{client.first_name} {client.last_name}
									</div>
									<div
										style={{
											fontSize: 12,
											color: "#666",
											display: "flex",
											flexDirection: "column",
										}}>
										<span>{client.email}</span>
										<span>{client.username}</span>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
				<ScrollView style={{ flex: 1 }}>
					{reports.map((report) => (
						<div
							key={report.id}
							onClick={() => {
								setSelectedOverview(report);
								setShowReportModal(true);
							}}
							style={{
								padding: 12,
								backgroundColor:
									selectedOverview?.id === report.id
										? `${APP_COLOURS.BG2}20`
										: "transparent",
								borderRadius: 6,
								marginBottom: 8,
								cursor: "pointer",
							}}>
							<span style={{ fontSize: 14, fontWeight: 500 }}>
								{report.subject}
							</span>
							<span style={{ fontSize: 14, fontWeight: 500 }}>
								{report.date}
							</span>
						</div>
					))}
				</ScrollView>
			</div>

			<ScrollView style={{ flex: 1, padding: 16 }}>
				{overviewGenerated ? (
					<>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								marginBottom: 16,
							}}>
							<p style={{ fontSize: 20, fontWeight: 600 }}>
								Overview from {overviewGenerated.date}
							</p>
							<div
								style={{
									display: "flex",
									gap: 10,
									flexDirection: "column",
									alignItems: "center",
									padding: "20px",
									marginTop: 20,
									backgroundColor: "#f8f9fa",
									borderRadius: 12,
									width: "100%",
									maxWidth: 600,
									alignSelf: "center",
								}}>
								<button
									onClick={handleGenerateOverview}
									disabled={isLoading}
									style={{
										backgroundColor: APP_COLOURS.BG2,
										padding: "8px 16px",
										borderRadius: 8,
										border: "none",
										cursor: "pointer",
									}}>
									<span
										style={{ color: "#FFF", fontSize: 14 }}>
										{isLoading
											? "Regenerating..."
											: "Regenerate"}
									</span>
								</button>
								<button
									onClick={() => setShowEmailModal(true)}
									style={{
										backgroundColor: APP_COLOURS.BG2,
										padding: "8px 16px",
										borderRadius: 8,
										border: "none",
										cursor: "pointer",
									}}>
									<span
										style={{ color: "#FFF", fontSize: 14 }}>
										Save & Send
									</span>
								</button>
							</div>
						</div>

						<div id='overview-container' style={{ flex: 1 }}>
							<div
								style={{ overflowY: "auto" }}
								dangerouslySetInnerHTML={{
									__html: overviewGenerated.content,
								}}
							/>
							<div
								style={{
									display: overviewGenerated?.roadmap
										? "flex"
										: "none",
									flexDirection: "column",
									alignItems: "center",
									// padding: "20px",
									marginTop: 20,
									backgroundColor: "#f8f9fa",
									borderRadius: 12,
									width: "100%",
									maxWidth: 600,
									alignSelf: "center",
								}}>
								<h3
									style={{
										marginBottom: 20,
										color: APP_COLOURS.BG2,
										fontSize: 18,
										fontWeight: 600,
									}}>
									Project Roadmap
								</h3>
								{overviewGenerated?.roadmap?.map(
									(item, index) => (
										<React.Fragment key={index}>
											<div
												style={{
													display: "flex",
													alignItems: "center",
													width: "100%",
													padding: "12px 16px",
													backgroundColor: "#fff",
													borderRadius: 8,
													// boxShadow:
													// 	"0 2px 4px rgba(0,0,0,0.05)",
													borderWidth: 0.5,
													borderColor:
														APP_COLOURS.BG2,
												}}>
												<div
													style={{
														width: 28,
														height: 28,
														borderRadius: "50%",
														border: `2px solid ${APP_COLOURS.BG2}`,
														backgroundColor:
															item.completed
																? APP_COLOURS.BG2
																: "transparent",
														display: "flex",
														justifyContent:
															"center",
														alignItems: "center",
														flexShrink: 0,
													}}>
													{item.completed && (
														<span
															style={{
																color: "#fff",
																fontSize: 14,
															}}>
															✓
														</span>
													)}
												</div>
												<div
													style={{
														marginLeft: 16,
														flex: 1,
													}}>
													<div
														style={{
															fontSize: 15,
															fontWeight: 500,
															color: "#2c3e50",
															marginBottom: 4,
														}}>
														{item.description}
													</div>
													<div
														style={{
															fontSize: 13,
															color: "#7f8c8d",
														}}>
														{moment(
															item.date,
														).format(
															"MMM DD, YYYY",
														)}
													</div>
												</div>
												<div
													style={{
														padding: "4px 12px",
														borderRadius: 12,
														backgroundColor:
															item.completed
																? `${APP_COLOURS.BG2}20`
																: "#f1f2f6",
														fontSize: 12,
														color: item.completed
															? APP_COLOURS.BG2
															: "#636e72",
														fontWeight: 500,
													}}>
													{item.completed
														? "Completed"
														: moment(
																item.date,
														  ).fromNow()}
												</div>
											</div>
											{index <
												overviewGenerated.roadmap
													.length -
													1 && (
												<div
													style={{
														width: 2,
														height: 24,
														backgroundColor: `${APP_COLOURS.BG2}40`,
														margin: "4px 0 4px 29px",
													}}
												/>
											)}
										</React.Fragment>
									),
								)}
							</div>
						</div>
					</>
				) : (
					<div
						style={{
							flex: 1,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}>
						{loading ? (
							<ActivityIndicator
								size='large'
								color={APP_COLOURS.BG2}
							/>
						) : (
							<button
								onClick={handleGenerateOverview}
								disabled={isLoading}
								style={{
									backgroundColor: APP_COLOURS.BG2,
									padding: "12px 24px",
									borderRadius: 8,
									border: "none",
									cursor: "pointer",
									boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
									opacity: isLoading ? 0.7 : 1,
								}}>
								<span
									style={{
										color: "#FFF",
										fontSize: 16,
										fontWeight: 600,
									}}>
									{isLoading
										? "Generating..."
										: "Generate New Overview"}
								</span>
							</button>
						)}
					</div>
				)}
			</ScrollView>

			{showClientModal && (
				<View
					style={{
						position: "fixed",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: "rgba(52, 52, 52, 0.6)",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}>
					<View
						onClick={() => setShowClientModal(false)}
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
						}}
					/>
					<View
						style={{
							width: 350,
							height: 300,
							backgroundColor: "#FFF",
							borderRadius: 15,
							padding: 10,
							zIndex: 999,
							boxShadow: "0 4px 6px rgba(0,0,0,0.23)",
						}}>
						<UsersSelectList
							assignment={clients}
							assignment_ids={clientIds}
							isStandalone={true}
							addAssignment={handleAddClients}
						/>
					</View>
				</View>
			)}

			{showEmailModal && (
				<div
					style={{
						position: "fixed",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: "rgba(52, 52, 52, 0.3)",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}>
					<div
						onClick={() => setShowEmailModal(false)}
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
						}}
					/>
					<div
						style={{
							width: 400,
							backgroundColor: "#FFF",
							borderRadius: 15,
							padding: 20,
							zIndex: 999,
							boxShadow: "0 4px 6px rgba(0,0,0,0.23)",
						}}>
						<h3 style={{ marginBottom: 16 }}>
							Send Overview to Clients
						</h3>
						<div style={{ marginBottom: 20 }}>
							{projectData?.clients?.map((client, index) => (
								<div
									key={client.id}
									style={{ marginBottom: 10 }}>
									<input
										type='checkbox'
										id={`client-${client.id}`}
										defaultChecked
										style={{ marginRight: 8 }}
									/>
									<label htmlFor={`client-${client.id}`}>
										{client.username}
									</label>
								</div>
							))}
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
								gap: 10,
							}}>
							<button
								onClick={() => setShowEmailModal(false)}
								style={{
									padding: "8px 16px",
									borderRadius: 4,
									border: `1px solid ${APP_COLOURS.BG2}`,
									backgroundColor: "white",
									cursor: "pointer",
								}}>
								Cancel
							</button>
							<button
								onClick={() => {
									let selectedEmails = "";
									projectData?.clients?.forEach((client) => {
										selectedEmails += `${client.username}, `;
									});
									handleSendEmail(selectedEmails);
								}}
								style={{
									backgroundColor: APP_COLOURS.BG2,
									padding: "8px 16px",
									borderRadius: 4,
									border: "none",
									cursor: "pointer",
								}}>
								<span style={{ color: "#FFF" }}>Send</span>
							</button>
						</div>
					</div>
				</div>
			)}

			{showReportModal && selectedOverview && (
				<Modal
					open={showReportModal}
					transparent={true}
					onClose={() => {
						setShowReportModal(false);
						setSelectedOverview(null);
					}}>
					<View
						style={{
							position: "fixed",
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							backgroundColor: "rgba(52, 52, 52, 0.6)",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							zIndex: 1000,
						}}>
						<div
							onClick={() => {
								setShowReportModal(false);
								setSelectedOverview(null);
							}}
							style={{
								position: "absolute",
								width: "100%",
								height: "100%",
							}}
						/>
						<View
							style={{
								width: "100%",
								maxWidth: 800,
								maxHeight: "90vh",
								backgroundColor: "#FFF",
								borderRadius: 15,
								padding: 20,
								zIndex: 999,
								boxShadow: "0 4px 6px rgba(0,0,0,0.23)",
								overflow: "auto",
							}}>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									marginBottom: 20,
									borderBottom: `1px solid ${APP_COLOURS.BG2}20`,
									paddingBottom: 10,
								}}>
								<h2 style={{ margin: 0 }}>
									{selectedOverview.subject}
								</h2>
								<button
									onClick={() => setShowReportModal(false)}
									style={{
										backgroundColor: "transparent",
										border: "none",
										cursor: "pointer",
										fontSize: 20,
										color: APP_COLOURS.BG2,
									}}>
									×
								</button>
							</div>

							<View
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									padding: "10px 20px",
									width: "100%",
									backgroundColor: APP_COLOURS.BG4,
									borderRadius: 10,
								}}>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										gap: "20px",
									}}>
									<h2
										style={{
											margin: 0,
											fontSize: "24px",
										}}>
										Project Overview
									</h2>
									<img
										onContextMenu='return false;'
										src='https://firebasestorage.googleapis.com/v0/b/tetricepm-5f972.appspot.com/o/skrumEmailAssets%2FArtboard.webp?alt=media&token=8bd73e39-87ed-4b5d-acf7-7df9df5ac5c5'
										alt='Work-Life Balance Image'
										style={{
											height: "150px",
											objectFit: "contain",
										}}
									/>
								</div>
							</View>

							<View style={{ marginBottom: 20 }}>
								<h3 style={{ color: APP_COLOURS.BG2 }}>
									Report Details
								</h3>
								<p>
									<strong>Date:</strong>{" "}
									{selectedOverview.date}
								</p>
								<p>
									<strong>Recipients:</strong>
								</p>
								<div
									style={{
										marginLeft: 20,
										display: "flex",
										flexDirection: "column",
										gap: 5,
									}}>
									{selectedOverview.recipients?.map(
										(recipient, index) => (
											<div key={index}>{recipient}</div>
										),
									)}
								</div>
							</View>

							<View>
								<h3 style={{ color: APP_COLOURS.BG2 }}>
									Content
								</h3>
								<div
									style={{
										padding: 15,
										backgroundColor: "#f8f9fa",
										borderRadius: 8,
									}}
									dangerouslySetInnerHTML={{
										__html: selectedOverview.overview,
									}}
								/>
							</View>
						</View>
					</View>
				</Modal>
			)}
		</View>
	);
};

export default ClientOverview;
