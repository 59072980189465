import React, { useState } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	TextInput,
	StyleSheet,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import { Avatars } from "../../APP_VARS";
import WhitePaperEditor from "./Editor/QuillEditor";
import { createNotice } from "../../CRUDHELPER";

const NewWhitepaper = ({ user_data, bgColor, mode, close }) => {
	const [title, setTitle] = useState("");

	return (
		<View style={styles.container}>
			<View style={[styles.mainView, { backgroundColor: bgColor }]}>
				<View style={styles.imageOverlay}>
					<View style={styles.bottomFade}>
						<Image
							source={require("./fade.png")}
							style={styles.fadeImage}
						/>
					</View>
				</View>

				<ScrollView showsVerticalScrollIndicator={false}>
					<View style={styles.contentContainer}>
						<View
							style={[
								styles.contentPadding,
								{ backgroundColor: bgColor },
							]}>
							<View style={styles.topContent}>
								<View style={styles.userInfoContainer}>
									<Image
										source={
											Avatars[parseInt(user_data.avatar)]
												.pp
										}
										style={styles.avatar}
									/>
									<View style={styles.userTextContainer}>
										<Text style={styles.userName}>
											{user_data.first_name}{" "}
											{user_data.last_name}
										</Text>
									</View>
								</View>

								<Text style={styles.dateText}>
									{moment().format("DD/MM/YYYY")}
								</Text>

								<View style={styles.titleInputContainer}>
									<Text style={styles.titleLabel}>Title</Text>
									<TextInput
										style={styles.titleInput}
										placeholderTextColor={"#797979"}
										autoCapitalize='sentences'
										multiline
										numberOfLines={5}
										clearButtonMode='while-editing'
										value={title}
										placeholder={`Type a title`}
										onChangeText={setTitle}
									/>
								</View>

								<WhitePaperEditor
									saveToParent={(html) => {
										const obj = {
											id: "",
											title: title,
											dateCreated:
												moment().format("DD/MM/YYYY"),
											html: html,
											text: "",
											image: "",
											bgColor: "#090D13",
											mode: "Dark",
											writtenBy: {
												first_name:
													user_data.first_name,
												last_name: user_data.last_name,
												avatar: user_data.avatar,
											},
										};
										createNotice(obj, close);
									}}
								/>

								<Text style={styles.signatureText}>
									Best regards,
								</Text>
								<Text style={styles.signatureName}>
									{user_data.first_name}
									<Text style={styles.signatureLastName}>
										{" "}
										{user_data.last_name}
									</Text>
								</Text>
							</View>
						</View>
					</View>
				</ScrollView>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		maxWidth: 800,
		width: "100%",
		alignSelf: "center",
		// backgroundColor: (props) => props.bgColor,
	},
	mainView: {
		flex: 1,
		// backgroundColor: (props) => props.bgColor,
	},
	imageOverlay: {
		position: "absolute",
		overflow: "hidden",
	},
	fadeImage: {
		width: "100%",
		height: 200,
		resizeMode: "stretch",
	},
	menuIcon: {
		position: "absolute",
		right: 20,
		top: 20,
	},
	menuImage: {
		width: 100,
		height: 100,
		resizeMode: "cover",
	},
	contentContainer: {
		flex: 1,
		opacity: 0.99,
		overflow: "hidden",
	},
	contentPadding: {
		padding: 20,
	},
	topContent: {
		// top: -180,
	},
	userInfoContainer: {
		width: "100%",
		height: "auto",
		padding: 7,
		alignItems: "center",
		borderRadius: 10,
		minHeight: 70,
		flexDirection: "row",
		borderColor: "#C5C5C5",
	},
	avatar: {
		width: 30,
		height: 30,
		borderRadius: 25,
		resizeMode: "contain",
		backgroundColor: "#FFF",
		borderWidth: 0.3,
		borderColor: "#C5C5C5",
		shadowColor: "#ECF4FF",
		shadowOffset: {
			width: 0,
			height: 8,
		},
		shadowOpacity: 0.44,
		shadowRadius: 10.32,
		elevation: 16,
	},
	userTextContainer: {
		padding: 5,
		borderRadius: 10,
		flexDirection: "column",
		flex: 1,
		alignItems: "flex-start",
	},
	userName: {
		fontFamily: "Gotham",
		fontSize: 12,
		fontWeight: "600",
		color: APP_COLOURS.WHITE,
	},
	dateText: {
		fontFamily: "Gotham",
		fontSize: 9,
		marginBottom: 10,
		fontWeight: "300",
		color: (props) =>
			props.mode === "Dark" ? APP_COLOURS.TEXTCOLOR : APP_COLOURS.BG3,
	},
	titleInputContainer: {
		width: "100%",
	},
	titleLabel: {
		fontWeight: "400",
		fontFamily: "Avenir Next",
		fontSize: 12,
		color: "#0098FD",
		marginBottom: 4,
		position: "absolute",
		top: -12,
		zIndex: 99,
		padding: 4,
		left: 15,
		borderRadius: 20,
		backgroundColor: (props) => props.bgColor,
	},
	titleInput: {
		fontSize: 19,
		color: "#FFF",
		fontWeight: "800",
		fontFamily: "Avenir Next",
		width: "100%",
		paddingLeft: 10,
		padding: 10,
		borderWidth: 1,
		borderColor: "#0098FD",
		borderRadius: 16,
	},
	signatureText: {
		fontSize: 16,
		fontFamily: "Gotham",
		fontWeight: "300",
		marginTop: 20,
		color: (props) =>
			props.mode === "Dark" ? APP_COLOURS.TEXTCOLOR : APP_COLOURS.BG3,
	},
	signatureName: {
		fontSize: 16,
		fontFamily: "Gotham",
		fontWeight: "500",
		marginTop: 10,
		color: (props) =>
			props.mode === "Dark" ? APP_COLOURS.TEXTCOLOR : APP_COLOURS.BG3,
	},
	signatureLastName: {
		fontFamily: "Gotham",
		fontWeight: "800",
	},
	bottomFade: {
		position: "absolute",
		bottom: 0,
		width: "100%",
	},
});

export default NewWhitepaper;
