import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	Alert,
	LayoutAnimation,
	Animated,
	TextInput,
	ActivityIndicator,
	StyleSheet,
} from "react-native";
import moment from "moment";
import debbie from "../../../assets/images/cloud.png";
import bin from "../../../assets/images/bin.png";
import { APP_COLOURS } from "../../APP_VARS";
import { Avatars, TaskStatus } from "../../APP_VARS";
import Fade from "react-reveal/Fade";
import {
	crudCreate,
	crudDelete,
	crudSendEmail,
	crudUpdate,
	sendDiscordMessage,
} from "../../CRUDHELPER";
// import FullCalendar from "../DailyPlanner/FullCalendar";
import CoverageCalendar from "../DailyPlanner/CoveragCalendar";
import UsersSelectList from "../UsersSelectList";
import SmallerTabs from "../TopTabs/SmallerTabs";
import ChatBox from "../DailyPlanner/ChatBox";
import FullCalendar from "../DailyPlanner/FullCalendar";
import StatusItem from "../DailyPlanner/StatusItem";

const collectionName = "projects_tasks";

const styles = StyleSheet.create({
	modalContainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
	},
	modalOverlay: {
		width: window.innerWidth,
		height: window.innerHeight,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: APP_COLOURS.BG2,
	},
	modalContent: {
		flex: 1,
		alignItems: "center",
		position: "absolute",
		zIndex: 10,
		width: "100%",
		height: "100%",
	},
	modalInner: {
		flex: 1,
		backgroundColor: "#F1F5FA",
		padding: 5,
		width: "100%",
		borderRadius: 15,
		maxWidth: 1200,
	},
	header: {
		flexDirection: "row",
		width: "100%",
		alignItems: "center",
		marginBottom: 10,
		height: "auto",
		paddingBottom: 10,
		borderRadius: 10,
		padding: 7,
	},
	headerLeft: {
		flex: 1,
		flexDirection: "row",
		alignItems: "center",
	},
	closeButton: {
		width: 25,
		height: 25,
		resizeMode: "contain",
	},
	titleText: {
		color: APP_COLOURS.BG2,
		fontSize: 19,
		marginLeft: 10,
		width: "100%",
		textAlign: "center",
	},
	assignmentContainer: {
		width: 90,
		justifyContent: "center",
	},
	emptyAssignment: {
		justifyContent: "center",
		alignItems: "center",
		opacity: 0.4,
	},
	emptyAssignmentText: {
		color: APP_COLOURS.BG2,
		fontSize: 9.1, // fs * 0.7
	},
	assignmentList: {
		flexDirection: "row",
	},
	avatarContainer: {
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#FFF",
		borderWidth: 2,
		borderColor: "#FFF",
		width: 35, // size
		height: 35, // size
		borderRadius: 17.5, // size/2
		shadowColor: "#6494B7",
		shadowOffset: {
			width: 0,
			height: 4,
		},
		shadowOpacity: 0.23,
		shadowRadius: 4.65,
		elevation: 8,
		zIndex: 1,
	},
	avatarImage: {
		width: 35, // size
		height: 35, // size
		borderRadius: 17.5, // size/2
		resizeMode: "contain",
		backgroundColor: APP_COLOURS.BG2,
	},
	textBox: {
		fontSize: 16,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		width: "100%",
		height: "auto",
		borderBottomWidth: 0.5,
		padding: 10,
		borderRadius: 10,
		backgroundColor: "#FFF",
	},
	mainContent: {
		flex: 1,
		flexDirection: "row",
	},
	leftPanel: {
		flex: 1,
		padding: 5,
		alignItems: "center",
		width: "100%",
	},
	rightPanel: {
		flex: 1,
		backgroundColor: "#FFF",
		padding: 5,
		borderRadius: 13,
		marginLeft: 10,
		maxWidth: 700,
	},
	labelText: {
		color: APP_COLOURS.BG2,
		fontSize: 11,
	},
	saveButton: {
		flex: 1,
		backgroundColor: APP_COLOURS.RED,
		justifyContent: "center",
		borderRadius: 20,
	},
	saveButtonText: {
		width: "100%",
		textAlign: "center",
		color: "white",
		fontSize: 10,
		fontWeight: "600",
		padding: 5,
	},
	taskContainer: {
		flex: 1,
		width: "100%",
		padding: 5,
	},
	inputContainer: {
		width: "100%",
	},
	descriptionContainer: {
		marginBottom: 10,
		width: "100%",
		marginVertical: 20,
	},
	calendarContainer: {
		width: 350,
	},
	datePickerRow: {
		width: 330,
		flexDirection: "row",
	},
	datePickerColumn: {
		marginTop: 20,
		flex: 1,
		marginRight: 10,
		opacity: 0.6,
	},
	datePickerColumnRight: {
		opacity: 0.6,
		marginTop: 20,
		flex: 1,
	},
	dateLabel: {
		fontWeight: "400",
		fontFamily: "Gotham",
		fontSize: 10,
		color: APP_COLOURS.BG2,
		marginBottom: 4,
		position: "absolute",
		top: -12,
		left: 9,
		backgroundColor: "#FFF",
		padding: 4,
		borderRadius: 8,
		zIndex: 99,
	},
	datePickerButton: {
		borderRadius: 7,
		borderColor: APP_COLOURS.BG2,
		borderWidth: 1,
	},
	datePickerButtonInner: {
		width: "100%",
		padding: 13,
		paddingLeft: 10,
	},
	dateText: {
		color: APP_COLOURS.BG2,
		fontWeight: "600",
		fontSize: 16,
	},
	buttonContainer: {
		width: "100%",
		height: 40,
		flexDirection: "row",
		justifyContent: "space-between",
		marginTop: 15,
	},
	deleteContainer: {
		padding: 10,
		width: "100%",
		flexDirection: "row",
	},
	deleteIcon: {
		width: 30,
		height: 30,
		marginRight: 5,
		resizeMode: "contain",
	},
	deleteText: {
		margin: 5,
		fontSize: 18,
	},
	extraAssignmentCount: {
		backgroundColor: "#252525",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		right: -17.5, // size / -2
		width: 35, // size
		height: 35, // size
		borderRadius: 15,
	},
	extraAssignmentText: {
		color: APP_COLOURS.BG2,
		fontSize: 9.1, // fs * 0.7
	},
	tabsContainer: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
	assignmentPage: {
		flex: 1,
		padding: 5,
		width: "100%",
	},
});

export default class EditTask extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props.d,

			dateAllocation: "start_date",
			loading: false,

			subPage: "Task",
		};
	}

	saveEdit = async () => {
		try {
			// Create a copy of the task data without brand if it's not available
			const taskData = {
				title: this.state.title,
				description: this.state.description,
				start_date: this.state.start_date,
				end_date: this.state.end_date,
				status: this.state.status,
				project_id: this.props.selectedProject?.id,
				milestone_id: this.props.d?.milestone_id,
				// Only include brand if it exists in selectedProject
				...(this.props.selectedProject?.brand && {
					brand: this.props.selectedProject.brand,
				}),
			};

			await crudUpdate(
				"projects_tasks",
				{
					...taskData,
					id: this.props.d.id,
				},
				(status) => {
					if (status === 200) {
						this.props.reloadData();
						this.props.close();
					}
				},
			);
		} catch (error) {
			console.error("Error saving task:", error);
		}
	};

	deleteItem = (d) => {
		let user_data = JSON.parse(localStorage.getItem("user_data"));
		sendDiscordMessage(
			`${user_data.first_name} ${user_data.last_name}`,
			`[Task Deleted]
- ${d.title}`,
			`${window.location.origin}${
				Avatars[parseInt(user_data.avatar)].pp
			}`,
			"0QPZYrY6zpAnJXqX9gQl",
		);
		crudDelete(collectionName, d, () => {
			this.props.close();
			this.props.reloadData();
		});
	};

	componentDidUpdate(prevProps) {
		// console.log("change", this.props.d);
		if (prevProps.d !== this.props.d) {
			this.setState({
				...this.props.d,
			});
		}
	}

	componentDidMount() {
		console.log("selectedProject", this.props.selectedProject);
	}

	handleStatusChange = async (_id, _status) => {
		let user_data = JSON.parse(localStorage.getItem("user_data"));

		// Find the project and handle potential undefined values
		let selectedProject = this.props.selectedProject || {};

		// Only send Discord notification if we have all required data
		if (this.props.d.assignment_ids?.length > 0) {
			const projectTitle =
				selectedProject?.brand?.title || "Untitled Project";
			const projectName = selectedProject.title || "";

			await Promise.all(
				this.props.d.assignment_ids.map(async (id) => {
					try {
						await sendDiscordMessage(
							`${user_data.first_name} ${user_data.last_name}`,
							`### *${projectTitle} - ${projectName}*
[Task Status] - *${_status}*
> ${this.props.d.title}
- url: ${window.location.origin}/home/projects?pid=${selectedProject.id}
`,
							`${window.location.origin}${
								Avatars[parseInt(user_data.avatar)].pp
							}`,
							id,
						);
					} catch (error) {
						console.error("Error sending Discord message:", error);
					}
				}),
			);
		}

		// Update task status
		crudUpdate(
			"projects_tasks",
			{
				id: _id,
				status: _status,
			},
			() => {
				this.props.reloadData();
			},
		);
	};

	render() {
		let color = APP_COLOURS.BG2;
		let d = this.props.d;

		let size = 35;
		let fs = 13;

		let textBox = {
			fontSize: 16,
			fontWeight: "600",
			color: color,
			width: "100%",
			height: "auto",
			borderBottomWidth: 0.5,
			padding: 10,
			borderRadius: 10,
			backgroundColor: "#FFF",
		};

		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View style={styles.modalContainer}>
					<TouchableOpacity
						style={styles.modalOverlay}
						onPress={() => this.props.close()}></TouchableOpacity>

					<View style={styles.modalContent}>
						<View style={styles.modalInner}>
							<View style={styles.header}>
								<View style={styles.headerLeft}>
									<TouchableOpacity
										onPress={() => this.props.close()}>
										<Image
											source={require("../../../assets/images/close.png")}
											style={styles.closeButton}
										/>
									</TouchableOpacity>
									<Text
										numberOfLines={1}
										ellipsizeMode='tail'
										style={styles.titleText}>
										{this.state.title}
									</Text>
								</View>
								<TouchableOpacity
									onPress={(d) => {
										this.setState({
											subPage: "Assignment",
										});
									}}>
									<View style={styles.assignmentContainer}>
										{d.assignment.length < 1 ? (
											<View
												style={styles.emptyAssignment}>
												<Text
													style={
														styles.emptyAssignmentText
													}>
													+ Assignment
												</Text>
											</View>
										) : (
											<View style={styles.assignmentList}>
												{d.assignment
													.slice(0, 5)
													.map((ass, i) => {
														return (
															<View
																key={i}
																style={
																	styles.avatarContainer
																}>
																<Image
																	source={
																		Avatars[
																			parseInt(
																				ass.avatar,
																			)
																		].pp
																	}
																	style={
																		styles.avatarImage
																	}
																/>
															</View>
														);
													})}
												{d.assignment.length > 5 ? (
													<View
														style={{
															backgroundColor:
																"#252525",
															flexDirection:
																"row",
															justifyContent:
																"center",
															alignItems:
																"center",
															right: size / -2,
															width: size,
															height: size,
															borderRadius: 15,
														}}>
														<Text
															style={{
																color: APP_COLOURS.BG2,
																fontSize:
																	fs * 0.7,
															}}>
															+
															{d.assignment
																.length - 5}
														</Text>
													</View>
												) : null}
											</View>
										)}
									</View>
								</TouchableOpacity>

								<StatusItem
									{...this.props}
									{...d}
									fs={fs}
									handleStatusChange={this.handleStatusChange}
								/>
							</View>

							<View style={styles.mainContent}>
								<View style={styles.leftPanel}>
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "space-between",
										}}>
										<SmallerTabs
											selectedSubPage={(d) => {
												this.setState({
													subPage: d,
												});
												console.log(d);
											}}
											tabs={[
												"Task",
												"Assignment",
												"Edit",
											]}
										/>
									</View>

									{this.state.subPage === "Task" ? (
										<View style={styles.taskContainer}>
											<ScrollView>
												<View
													style={
														styles.inputContainer
													}>
													<Text
														style={
															styles.labelText
														}>
														Task title
													</Text>
													<TextInput
														returnKeyType='done'
														autoCorrect={false}
														style={textBox}
														value={this.state.title}
														editable={true}
														onChangeText={(text) =>
															this.setState({
																title: text,
															})
														}
														multiline
														numberOfLines={5}
														autoCapitalize='sentences'
														placeholder='Task Name'
														ref='taskName'
														clearButtonMode='while-editing'
													/>
												</View>
												<View
													style={
														styles.descriptionContainer
													}>
													<Text
														style={
															styles.labelText
														}>
														Description
													</Text>
													<TextInput
														returnKeyType='done'
														autoCorrect={false}
														style={textBox}
														value={
															this.state
																.description
														}
														editable={true}
														onChangeText={(text) =>
															this.setState({
																description:
																	text,
															})
														}
														multiline
														numberOfLines={10}
														autoCapitalize='sentences'
														placeholder='Description'
														ref='taskName'
														clearButtonMode='while-editing'
														autoFocus
													/>
												</View>
												<View
													style={
														styles.calendarContainer
													}>
													<CoverageCalendar
														compWidth={350}
														calendarList={[]}
														selectedDate={
															this.state[
																this.state
																	.start_date
															]
														}
														setStartDate={moment(
															this.state
																.start_date,
														).format("DD/MM/YYYY")}
														setEndtDate={moment(
															this.state.end_date,
														).format("DD/MM/YYYY")}
														startDate={(date) => {
															this.setState({
																start_date:
																	date,
															});
														}}
														endDate={(date) => {
															this.setState({
																end_date: date,
															});
														}}
													/>
													<View
														style={
															styles.datePickerRow
														}>
														<View
															style={
																styles.datePickerColumn
															}>
															<Text
																style={
																	styles.dateLabel
																}>
																Start date
															</Text>
															<View
																style={
																	styles.datePickerButton
																}>
																<TouchableOpacity
																	onPress={() => {
																		this.setState(
																			{
																				dateAllocation:
																					"start_date",
																				showCalendar:
																					!this
																						.state
																						.showCalendar,
																			},
																		);
																	}}>
																	<View
																		style={
																			styles.datePickerButtonInner
																		}>
																		<Text
																			style={
																				styles.dateText
																			}>
																			{moment(
																				this
																					.state
																					.start_date,
																			)
																				.endOf(
																					"day",
																				)
																				.format(
																					"DD/MM/YYYY",
																				)}
																		</Text>
																	</View>
																</TouchableOpacity>
															</View>
														</View>
														<View
															style={
																styles.datePickerColumnRight
															}>
															<Text
																style={
																	styles.dateLabel
																}>
																Due date
															</Text>
															<View
																style={
																	styles.datePickerButton
																}>
																<TouchableOpacity
																	onPress={() => {
																		this.setState(
																			{
																				dateAllocation:
																					"end_date",
																			},
																		);
																	}}>
																	<View
																		style={
																			styles.datePickerButtonInner
																		}>
																		<Text
																			style={
																				styles.dateText
																			}>
																			{moment(
																				this
																					.state
																					.end_date,
																			).format(
																				"DD/MM/YYYY",
																			)}
																		</Text>
																	</View>
																</TouchableOpacity>
															</View>
														</View>
													</View>
												</View>
												<View
													style={
														styles.buttonContainer
													}>
													<TouchableOpacity
														disabled={
															this.state.loading
														}
														onPress={() =>
															this.saveEdit()
														}
														style={
															styles.saveButton
														}>
														{this.state.loading ? (
															<ActivityIndicator
																color={"#FFF"}
															/>
														) : (
															<Text
																style={
																	styles.saveButtonText
																}>
																SAVE
															</Text>
														)}
													</TouchableOpacity>
												</View>
											</ScrollView>
										</View>
									) : null}

									{this.state.subPage === "Assignment" ? (
										<View style={styles.assignmentPage}>
											<UsersSelectList
												assignment={
													this.state.assignment
												}
												assignment_ids={
													this.state.assignment_ids
												}
												isStandalone={false}
												addAssignment={(ass, ids) => {
													this.setState({
														assignment: ass,
														assignment_ids: ids,
													});
												}}
											/>
											<View
												style={styles.buttonContainer}>
												<TouchableOpacity
													disabled={
														this.state.loading
													}
													onPress={() =>
														this.saveEdit()
													}
													style={styles.saveButton}>
													{this.state.loading ? (
														<ActivityIndicator
															color={"#FFF"}
														/>
													) : (
														<Text
															style={
																styles.saveButtonText
															}>
															SAVE
														</Text>
													)}
												</TouchableOpacity>
											</View>
										</View>
									) : null}

									{this.state.subPage === "Edit" ? (
										<View style={styles.deleteContainer}>
											<TouchableOpacity
												onPress={() => {
													this.deleteItem(this.state);
												}}
												style={styles.deleteIcon}>
												<Image
													source={bin}
													style={styles.deleteIcon}
												/>
											</TouchableOpacity>
											<Text style={styles.deleteText}>
												Delete Task
											</Text>
										</View>
									) : null}
								</View>

								<View style={styles.rightPanel}>
									<ChatBox
										chatTitle={this.state.title}
										project_id={this.props.d.id}
										selectedProject={
											this.props.selectedProject
										}
										assignment_ids={
											this.state.assignment_ids
										}
										user_data={JSON.parse(
											localStorage.getItem("user_data"),
										)}
									/>
								</View>
							</View>
						</View>
					</View>
				</View>
			</Modal>
		);
	}
}
