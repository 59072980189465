import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	ActivityIndicator,
	Modal,
	Image,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import ProgressBar from "../DailyPlanner/ProgressBar";
import { crudGetProjects, crudGetTasks, crudReadUnass } from "../../CRUDHELPER";
import ProjectView from "./ProjectView";

export default class ClientView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			projects: props.projects || [],
			tasks: props.tasks || [],
			milestones: props.milestones || [],
			selectedProject: null,
			loading: false,
			allTasks: [],
			showFullScreen: false,
			currentProjectIndex: 0,
		};
	}

	componentDidMount() {
		this.getAllProjects();
		this.getAllTasks();
	}

	getAllProjects = () => {
		this.setState({ loading: true });

		crudGetProjects({ archive: false }, (status, data) => {
			if (status === 200) {
				this.setState({
					projects: data,
					loading: false,
				});
				console.log(data);
			}
		});
	};

	getAllTasks = () => {
		this.setState({ loading: true });

		crudReadUnass("projects_tasks", "", (status, data) => {
			if (status === 200) {
				this.setState({
					allTasks: data,
					loading: false,
				});
			}
		});
	};

	componentDidUpdate(prevProps) {
		if (prevProps.projects !== this.props.projects) {
			this.setState({ projects: this.props.projects });
		}
		if (prevProps.tasks !== this.props.tasks) {
			this.setState({ tasks: this.props.tasks });
		}
		if (prevProps.milestones !== this.props.milestones) {
			this.setState({ milestones: this.props.milestones });
		}
	}

	calculateProgress = (projectId) => {
		const projectTasks = this.state.allTasks.filter(
			(t) => t.project_id === projectId,
		);

		const stats = {
			total: projectTasks.length,
			completed: projectTasks.filter((t) => t.status === "Completed")
				.length,
			inProgress: projectTasks.filter((t) => t.status === "In Progress")
				.length,
			unassigned: projectTasks.filter(
				(t) => !t.assignment_ids || t.assignment_ids.length === 0,
			).length,
			progress: 0,
		};

		if (stats.total > 0) {
			stats.progress = (stats.completed / stats.total) * 100;
		}

		return stats;
	};

	toggleFullScreen = () => {
		this.setState((prevState) => ({
			showFullScreen: !prevState.showFullScreen,
			currentProjectIndex: 0,
		}));
	};

	nextProject = () => {
		this.setState((prevState) => ({
			currentProjectIndex:
				(prevState.currentProjectIndex + 1) %
				this.state.projects.length,
		}));
	};

	previousProject = () => {
		this.setState((prevState) => ({
			currentProjectIndex:
				prevState.currentProjectIndex === 0
					? this.state.projects.length - 1
					: prevState.currentProjectIndex - 1,
		}));
	};

	renderProject = (project) => {
		const stats = this.calculateProgress(project.id);
		const projectTasks = this.state.allTasks.filter(
			(t) => t.project_id === project.id,
		);
		const projectMilestones = this.state.milestones.filter(
			(m) => m.project_id === project.id,
		);

		return (
			<View key={project.id} style={styles.projectCard}>
				<View style={styles.projectHeader}>
					<View style={styles.titleContainer}>
						{project.brand?.imageData?.url && (
							<Image
								source={{ uri: project.brand.imageData.url }}
								style={styles.brandLogo}
							/>
						)}
						<Text style={styles.projectTitle}>{project.title}</Text>
					</View>
					<Text
						style={[
							styles.projectStatus,
							{
								backgroundColor:
									project.status === "Attention Required"
										? "#FED7D7"
										: "#EBF8FF",
								color:
									project.status === "Attention Required"
										? "#E53E3E"
										: "#4299E1",
							},
						]}>
						{project.status}
					</Text>
				</View>

				<View style={styles.progressSection}>
					<ProgressBar progress={project.progress || 0} />
					<Text style={styles.progressText}>
						{`${Math.round(project.progress || 0)}% Complete`}
					</Text>
				</View>

				<View style={styles.assignmentSection}>
					<Text style={styles.sectionTitle}>Team Members</Text>
					<ScrollView
						horizontal
						showsHorizontalScrollIndicator={false}>
						{project.assignment?.map((member) => (
							<View key={member.id} style={styles.memberCard}>
								<View
									style={[
										styles.avatar,
										{ backgroundColor: APP_COLOURS.BG2 },
									]}>
									<Text style={styles.avatarText}>
										{member.first_name[0]}
										{member.last_name[0]}
									</Text>
								</View>
								<Text style={styles.memberName}>
									{member.first_name} {member.last_name}
								</Text>
								<Text style={styles.memberRole}>
									{member.position || "Team Member"}
								</Text>
							</View>
						))}
					</ScrollView>
				</View>

				<View style={styles.statsContainer}>
					<View style={styles.statItem}>
						<Text style={styles.statNumber}>{stats.total}</Text>
						<Text style={styles.statLabel}>Total Tasks</Text>
					</View>
					<View style={styles.statItem}>
						<Text style={styles.statNumber}>{stats.completed}</Text>
						<Text style={styles.statLabel}>Completed</Text>
					</View>
					<View style={styles.statItem}>
						<Text style={styles.statNumber}>
							{stats.inProgress}
						</Text>
						<Text style={styles.statLabel}>In Progress</Text>
					</View>
					<View style={styles.statItem}>
						<Text style={styles.statNumber}>
							{stats.unassigned}
						</Text>
						<Text style={styles.statLabel}>Unassigned</Text>
					</View>
				</View>

				<View style={styles.dateSection}>
					<Text style={styles.dateText}>
						Due:{" "}
						{project.due_date
							? moment(project.due_date).format("DD MMM YYYY")
							: "No due date"}
					</Text>
				</View>

				{projectMilestones.length > 0 && (
					<View style={styles.section}>
						<Text style={styles.sectionTitle}>Milestones</Text>
						{projectMilestones.map((milestone) => (
							<View
								key={milestone.id}
								style={styles.milestoneItem}>
								<Text style={styles.milestoneText}>
									{milestone.name}
								</Text>
								<Text style={styles.milestoneDate}>
									{moment(milestone.due_date).format(
										"DD MMM YYYY",
									)}
								</Text>
							</View>
						))}
					</View>
				)}

				<View style={styles.taskList}>
					<Text style={styles.sectionTitle}>Recent Tasks</Text>
					{projectTasks.slice(0, 3).map((task) => (
						<View key={task.id} style={styles.taskItem}>
							<View style={styles.taskHeader}>
								<Text style={styles.taskText}>{task.name}</Text>
								<Text
									style={[
										styles.taskStatus,
										{
											color:
												task.status === "Completed"
													? APP_COLOURS.GREEN
													: APP_COLOURS.ORANGE,
										},
									]}>
									{task.assignment_ids?.length
										? "Assigned"
										: "Unassigned"}
								</Text>
							</View>
							<Text style={styles.taskStatus}>{task.status}</Text>
						</View>
					))}
				</View>
			</View>
		);
	};

	render() {
		return (
			<View style={styles.container}>
				<View style={styles.headerContainer}>
					<Text style={styles.header}>Project Overview</Text>
					<TouchableOpacity
						style={styles.fullScreenButton}
						onPress={this.toggleFullScreen}>
						<Text style={styles.fullScreenButtonText}>
							Full Screen View
						</Text>
					</TouchableOpacity>
				</View>

				{this.state.loading ? (
					<ActivityIndicator size='large' color={APP_COLOURS.BG2} />
				) : (
					<ScrollView style={styles.scrollView}>
						{this.state.projects.map((project) =>
							this.renderProject(project),
						)}
					</ScrollView>
				)}

				{this.state.showFullScreen && (
					<Modal
						animationType='slide'
						transparent={false}
						visible={this.state.showFullScreen}>
						<View style={styles.modalContainer}>
							<View style={styles.modalHeader}>
								<TouchableOpacity
									onPress={this.toggleFullScreen}
									style={styles.closeButton}>
									<Text style={styles.closeButtonText}>
										Close
									</Text>
								</TouchableOpacity>
								<Image
									source={require("../../../assets/images/icons/skrum_menu.png")}
									style={{
										width: 30,
										height: 30,
										resizeMode: "contain",
									}}
								/>
								<Text style={styles.modalTitle}>
									Project {this.state.currentProjectIndex + 1}{" "}
									of {this.state.projects.length}
								</Text>
							</View>

							<ProjectView
								project={
									this.state.projects[
										this.state.currentProjectIndex
									]
								}
								stats={this.calculateProgress(
									this.state.projects[
										this.state.currentProjectIndex
									].id,
								)}
								milestones={this.state.milestones.filter(
									(m) =>
										m.project_id ===
										this.state.projects[
											this.state.currentProjectIndex
										].id,
								)}
								tasks={this.state.allTasks.filter(
									(t) =>
										t.project_id ===
										this.state.projects[
											this.state.currentProjectIndex
										].id,
								)}
							/>

							<View style={styles.navigationButtons}>
								<TouchableOpacity
									onPress={this.previousProject}
									style={styles.navButton}>
									<Text style={styles.navButtonText}>
										Previous
									</Text>
								</TouchableOpacity>
								<TouchableOpacity
									onPress={this.nextProject}
									style={styles.navButton}>
									<Text style={styles.navButtonText}>
										Next
									</Text>
								</TouchableOpacity>
							</View>
						</View>
					</Modal>
				)}
			</View>
		);
	}
}

const styles = {
	container: {
		flex: 1,
		backgroundColor: "#F5F7FA", // Light gray background
		padding: 20,
	},
	header: {
		fontSize: 28,
		fontWeight: "800",
		color: "#2D3748", // Dark gray text
		marginBottom: 20,
		fontFamily: "Gotham",
	},
	scrollView: {
		flex: 1,
	},
	projectCard: {
		backgroundColor: "#FFFFFF", // White background
		borderRadius: 16,
		padding: 24,
		marginBottom: 20,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 4 },
		shadowOpacity: 0.1,
		shadowRadius: 12,
		elevation: 5,
	},
	projectHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 20,
	},
	projectTitle: {
		fontSize: 20,
		fontWeight: "600",
		color: "#2D3748", // Dark gray text
		fontFamily: "Gotham",
	},
	projectStatus: {
		fontSize: 14,
		color: "#4299E1", // Blue accent
		fontFamily: "Gotham",
		backgroundColor: "#EBF8FF", // Light blue background
		paddingHorizontal: 12,
		paddingVertical: 6,
		borderRadius: 20,
	},
	progressSection: {
		marginBottom: 20,
	},
	progressText: {
		fontSize: 14,
		color: "#4A5568", // Medium gray text
		marginTop: 8,
		fontFamily: "Gotham",
	},
	dateSection: {
		marginBottom: 20,
	},
	dateText: {
		fontSize: 14,
		color: "#4A5568", // Medium gray text
		fontFamily: "Gotham",
	},
	section: {
		marginTop: 20,
	},
	sectionTitle: {
		fontSize: 16,
		fontWeight: "600",
		color: "#2D3748", // Dark gray text
		marginBottom: 12,
		fontFamily: "Gotham",
	},
	milestoneItem: {
		marginBottom: 12,
		padding: 16,
		backgroundColor: "#F7FAFC", // Very light gray
		borderRadius: 12,
		borderLeft: "4px solid #4299E1", // Blue accent border
	},
	milestoneText: {
		fontSize: 14,
		color: "#2D3748", // Dark gray text
		marginBottom: 6,
		fontFamily: "Gotham",
	},
	milestoneDate: {
		fontSize: 12,
		color: "#718096", // Gray text
		fontFamily: "Gotham",
	},
	taskItem: {
		marginBottom: 12,
		padding: 16,
		backgroundColor: "#F7FAFC", // Very light gray
		borderRadius: 12,
	},
	taskHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	taskText: {
		fontSize: 14,
		color: "#2D3748", // Dark gray text
		flex: 1,
		fontFamily: "Gotham",
	},
	taskStatus: {
		fontSize: 12,
		fontFamily: "Gotham",
	},
	statsContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginVertical: 20,
		padding: 16,
		backgroundColor: "#F7FAFC", // Very light gray
		borderRadius: 12,
	},
	statItem: {
		alignItems: "center",
		flex: 1,
	},
	statNumber: {
		fontSize: 24,
		fontWeight: "600",
		color: "#2D3748", // Dark gray text
		fontFamily: "Gotham",
	},
	statLabel: {
		fontSize: 12,
		color: "#718096", // Gray text
		marginTop: 6,
		fontFamily: "Gotham",
	},
	taskList: {
		marginTop: 20,
	},
	headerContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 24,
	},
	fullScreenButton: {
		backgroundColor: "#4299E1", // Blue accent
		paddingHorizontal: 16,
		paddingVertical: 10,
		borderRadius: 8,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.1,
		shadowRadius: 4,
	},
	fullScreenButtonText: {
		color: "#FFFFFF",
		fontFamily: "Gotham",
		fontSize: 14,
		fontWeight: "500",
	},
	modalContainer: {
		flex: 1,
		backgroundColor: "#F5F7FA", // Light gray background
	},
	modalHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		padding: 20,
		backgroundColor: APP_COLOURS.BG2,
		borderBottomWidth: 1,
		borderBottomColor: "#E2E8F0",
	},
	closeButton: {
		padding: 10,
	},
	closeButtonText: {
		color: "#4299E1", // Blue accent
		fontSize: 16,
		fontFamily: "Gotham",
	},
	modalTitle: {
		color: "#2D3748", // Dark gray text
		fontSize: 16,
		fontFamily: "Gotham",
		fontWeight: "600",
	},
	navigationButtons: {
		flexDirection: "row",
		justifyContent: "space-between",
		padding: 20,
		backgroundColor: APP_COLOURS.BG2,
		borderTopWidth: 1,
		borderTopColor: "#E2E8F0",
	},
	navButton: {
		backgroundColor: "#4299E1", // Blue accent
		paddingHorizontal: 20,
		paddingVertical: 12,
		borderRadius: 8,
		width: 120,
		alignItems: "center",
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.1,
		shadowRadius: 4,
	},
	navButtonText: {
		color: "#FFFFFF",
		fontFamily: "Gotham",
		fontSize: 14,
		fontWeight: "500",
	},
	titleContainer: {
		flexDirection: "row",
		alignItems: "center",
		flex: 1,
		marginRight: 16,
	},
	brandLogo: {
		width: 40,
		height: 40,
		marginRight: 12,
		borderRadius: 8,
	},
	assignmentSection: {
		marginVertical: 20,
	},
	memberCard: {
		backgroundColor: "#FFFFFF",
		padding: 12,
		borderRadius: 12,
		marginRight: 16,
		alignItems: "center",
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.1,
		shadowRadius: 4,
		elevation: 3,
		width: 120,
	},
	avatar: {
		width: 48,
		height: 48,
		borderRadius: 24,
		alignItems: "center",
		justifyContent: "center",
		marginBottom: 8,
	},
	avatarText: {
		color: "#FFFFFF",
		fontSize: 16,
		fontWeight: "600",
		fontFamily: "Gotham",
	},
	memberName: {
		fontSize: 14,
		color: "#2D3748",
		fontWeight: "500",
		textAlign: "center",
		marginBottom: 4,
		fontFamily: "Gotham",
	},
	memberRole: {
		fontSize: 12,
		color: "#718096",
		textAlign: "center",
		fontFamily: "Gotham",
	},
	presentationMode: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: "#000000",
	},
};
