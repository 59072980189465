import React, { Component } from "react";
import { View, Text, ScrollView, TouchableOpacity } from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import {
	AiOutlineHome,
	AiOutlineTeam,
	AiOutlineFile,
	AiOutlineMessage,
	AiOutlineEdit,
	AiOutlineBarChart,
	AiOutlineSchedule,
	AiOutlineCalendar,
} from "react-icons/ai";

export default class SmallerTabs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: this.props.tabs[0],
		};
	}

	render() {
		// Helper function to get icon for each tab
		const getIconForTab = (tabName) => {
			const iconColor =
				this.state.selected === tabName ? "#FFF" : APP_COLOURS.BG2;
			const iconSize = 20;

			switch (tabName.toLowerCase()) {
				case "home":
				case "overview":
					return <AiOutlineHome size={iconSize} color={iconColor} />;
				case "clients":
					return <AiOutlineTeam size={iconSize} color={iconColor} />;
				case "edit":
					return <AiOutlineEdit size={iconSize} color={iconColor} />;
				case "documents":
					return <AiOutlineFile size={iconSize} color={iconColor} />;
				case "dates":
					return (
						<AiOutlineCalendar size={iconSize} color={iconColor} />
					);
				case "tasks":
					return (
						<AiOutlineSchedule size={iconSize} color={iconColor} />
					);
				case "calendar":
					return (
						<AiOutlineCalendar size={iconSize} color={iconColor} />
					);
				case "chat":
					return (
						<AiOutlineMessage size={iconSize} color={iconColor} />
					);
				default:
					return null;
			}
		};

		return (
			<View style={{}}>
				<ScrollView showsHorizontalScrollIndicator={false} horizontal>
					<View
						style={{
							flexDirection: "row",
						}}>
						{this.props.tabs.map((d, i) => (
							<TouchableOpacity
								key={i}
								style={{}}
								onPress={() => {
									this.setState({
										selected: d,
									});
									this.props.selectedSubPage(d);
								}}>
								<View
									style={{
										width: "auto",
										//   height: 50,
										justifyContent: "center",
										alignItems: "center",
										// borderColor:
										// 	this.state.selected === d
										// 		? APP_COLOURS.BLUE
										// 		: APP_COLOURS.BG2,
										// borderWidth: 0.5,
										backgroundColor:
											this.state.selected === d
												? APP_COLOURS.BLUE
												: "#FFF",
										padding: 10,
										// borderRadius: 10,
										marginRight: 5,
										borderTopLeftRadius: 10,
										borderTopRightRadius: 10,
										paddingHorizontal: 15,
										paddingTop: 15,
										flexDirection: "row",
										alignItems: "center",
										gap: 5,
									}}>
									{getIconForTab(d)}
									<Text
										style={{
											fontSize: 15,
											color:
												this.state.selected === d
													? "#FFF"
													: APP_COLOURS.BG2,
											fontWeight: "400",
										}}>
										{d}
									</Text>
								</View>
							</TouchableOpacity>
						))}
					</View>
				</ScrollView>
			</View>
		);
	}
}
