import React from "react";
import { View, Text, ScrollView, Image, TouchableOpacity } from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import ProgressBar from "../DailyPlanner/ProgressBar";

const SENTIMENT_EMOJIS = {
	EXCELLENT: "😄",
	GOOD: "🙂",
	NEUTRAL: "😐",
	CONCERNED: "😟",
	CRITICAL: "😰",
};

const TabButton = ({ title, isActive, onPress }) => (
	<TouchableOpacity
		onPress={onPress}
		style={[styles.tabButton, isActive && styles.activeTabButton]}>
		<Text style={[styles.tabButtonText, isActive && styles.activeTabText]}>
			{title}
		</Text>
	</TouchableOpacity>
);

const ProjectView = ({ project, stats, milestones, tasks }) => {
	const [activeTab, setActiveTab] = React.useState("overview");
	const [activeSubTab, setActiveSubTab] = React.useState("status");

	if (!project) return null;

	const renderOverviewTab = () => (
		<View>
			<ScrollView
				horizontal
				showsHorizontalScrollIndicator={false}
				style={styles.subTabsContainer}>
				<TabButton
					title='Status'
					isActive={activeSubTab === "status"}
					onPress={() => setActiveSubTab("status")}
				/>
				<TabButton
					title='Notes'
					isActive={activeSubTab === "notes"}
					onPress={() => setActiveSubTab("notes")}
				/>
				<TabButton
					title='Sentiment'
					isActive={activeSubTab === "sentiment"}
					onPress={() => setActiveSubTab("sentiment")}
				/>
			</ScrollView>

			{activeSubTab === "status" && (
				<View style={styles.tabContent}>
					<View style={styles.progressSection}>
						<ProgressBar progress={project.progress || 0} />
						<Text style={styles.progressText}>
							{`${Math.round(project.progress || 0)}% Complete`}
						</Text>
					</View>

					<View style={styles.statsContainer}>
						<View style={styles.statItem}>
							<Text style={styles.statNumber}>{stats.total}</Text>
							<Text style={styles.statLabel}>Total Tasks</Text>
						</View>
						<View style={styles.statItem}>
							<Text style={styles.statNumber}>
								{stats.completed}
							</Text>
							<Text style={styles.statLabel}>Completed</Text>
						</View>
						<View style={styles.statItem}>
							<Text style={styles.statNumber}>
								{stats.inProgress}
							</Text>
							<Text style={styles.statLabel}>In Progress</Text>
						</View>
						<View style={styles.statItem}>
							<Text style={styles.statNumber}>
								{stats.unassigned}
							</Text>
							<Text style={styles.statLabel}>Unassigned</Text>
						</View>
					</View>
				</View>
			)}

			{activeSubTab === "notes" && (
				<View style={styles.tabContent}>
					<Text style={styles.sectionTitle}>Project Notes</Text>
					<View style={styles.noteCard}>
						<Text style={styles.noteText}>
							{project.notes || "No notes available"}
						</Text>
					</View>
				</View>
			)}

			{activeSubTab === "sentiment" && (
				<View style={styles.tabContent}>
					<Text style={styles.sectionTitle}>Project Sentiment</Text>
					<View style={styles.sentimentContainer}>
						{Object.entries(SENTIMENT_EMOJIS).map(
							([key, emoji]) => (
								<TouchableOpacity
									key={key}
									style={[
										styles.sentimentEmoji,
										project.sentiment === key &&
											styles.selectedSentiment,
									]}>
									<Text style={styles.emojiText}>
										{emoji}
									</Text>
								</TouchableOpacity>
							),
						)}
					</View>
				</View>
			)}
		</View>
	);

	const renderActivitiesTab = () => (
		<View style={styles.tabContent}>
			<Text style={styles.sectionTitle}>Recent Activities</Text>
			<View style={styles.activitiesContainer}>
				<View style={styles.activitySection}>
					<Text style={styles.subSectionTitle}>Completed Tasks</Text>
					{tasks
						.filter((task) => task.status === "Completed")
						.map((task) => (
							<View key={task.id} style={styles.activityItem}>
								<Text style={styles.activityText}>
									{task.name}
								</Text>
								<Text style={styles.activityDate}>
									{moment(task.completion_date).format(
										"DD MMM YYYY",
									)}
								</Text>
							</View>
						))}
				</View>

				<View style={styles.activitySection}>
					<Text style={styles.subSectionTitle}>
						Completed Milestones
					</Text>
					{milestones
						.filter((milestone) => milestone.status === "Completed")
						.map((milestone) => (
							<View
								key={milestone.id}
								style={styles.activityItem}>
								<Text style={styles.activityText}>
									{milestone.name}
								</Text>
								<Text style={styles.activityDate}>
									{moment(milestone.completion_date).format(
										"DD MMM YYYY",
									)}
								</Text>
							</View>
						))}
				</View>
			</View>
		</View>
	);

	return (
		<ScrollView style={styles.container}>
			<View style={styles.projectHeader}>
				<View style={styles.titleContainer}>
					{project.brand?.imageData?.url ? (
						<Image
							source={{
								uri: project.brand.imageData.url,
							}}
							style={styles.brandLogo}
						/>
					) : (
						<Image
							source={require("../../../assets/images/icons/skrum_menu.png")}
							style={{
								width: 150,
								height: 150,
								resizeMode: "contain",
								marginRight: 20,
								opacity: 0.2,
							}}
						/>
					)}
					<Text style={styles.projectTitle}>{project.title}</Text>
				</View>
				<Text
					style={[
						styles.projectStatus,
						{
							backgroundColor:
								project.status === "Attention Required"
									? "#FED7D7"
									: "#EBF8FF",
							color:
								project.status === "Attention Required"
									? "#E53E3E"
									: "#4299E1",
						},
					]}>
					{project.status}
				</Text>
			</View>
			<ScrollView
				horizontal
				showsHorizontalScrollIndicator={false}
				style={styles.mainTabsContainer}>
				<TabButton
					title='Overview'
					isActive={activeTab === "overview"}
					onPress={() => setActiveTab("overview")}
				/>
				<TabButton
					title='Activities'
					isActive={activeTab === "activities"}
					onPress={() => setActiveTab("activities")}
				/>
				<TabButton
					title='Pending'
					isActive={activeTab === "pending"}
					onPress={() => setActiveTab("pending")}
				/>
				<TabButton
					title='Roadmap'
					isActive={activeTab === "roadmap"}
					onPress={() => setActiveTab("roadmap")}
				/>
				<TabButton
					title='Action Plan'
					isActive={activeTab === "action-plan"}
					onPress={() => setActiveTab("action-plan")}
				/>
				<TabButton
					title='Resources'
					isActive={activeTab === "resources"}
					onPress={() => setActiveTab("resources")}
				/>
				<TabButton
					title='Sprints'
					isActive={activeTab === "sprints"}
					onPress={() => setActiveTab("sprints")}
				/>
			</ScrollView>

			<View style={styles.tabContentContainer}>
				{activeTab === "overview" && renderOverviewTab()}
				{activeTab === "activities" && renderActivitiesTab()}
			</View>
		</ScrollView>
	);
};

const styles = {
	container: {
		flex: 1,
		backgroundColor: "#F5F7FA",
		padding: 20,
	},
	projectCard: {
		backgroundColor: "#FFFFFF",
		borderRadius: 16,
		padding: 24,
		marginBottom: 20,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 4 },
		shadowOpacity: 0.1,
		shadowRadius: 12,
		elevation: 5,
	},
	titleContainer: {
		flexDirection: "row",
		alignItems: "center",
		flex: 1,
	},
	brandLogo: {
		width: 150,
		height: 150,
		marginRight: 20,
		borderRadius: 8,
		resizeMode: "contain",
	},
	projectHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 20,
	},
	projectTitle: {
		fontSize: 20,
		fontWeight: "600",
		color: "#2D3748",
		fontFamily: "Gotham",
	},
	projectStatus: {
		fontSize: 14,
		fontFamily: "Gotham",
		paddingHorizontal: 12,
		paddingVertical: 6,
		borderRadius: 20,
	},
	progressSection: {
		marginBottom: 20,
	},
	progressText: {
		fontSize: 14,
		color: "#4A5568",
		marginTop: 8,
		fontFamily: "Gotham",
	},
	assignmentSection: {
		marginBottom: 20,
	},
	memberCard: {
		alignItems: "center",
		marginRight: 16,
		width: 100,
	},
	avatar: {
		width: 50,
		height: 50,
		borderRadius: 25,
		alignItems: "center",
		justifyContent: "center",
		marginBottom: 8,
	},
	avatarText: {
		color: "#FFFFFF",
		fontSize: 16,
		fontWeight: "600",
	},
	memberName: {
		fontSize: 12,
		color: "#2D3748",
		textAlign: "center",
		fontFamily: "Gotham",
	},
	memberRole: {
		fontSize: 11,
		color: "#718096",
		textAlign: "center",
		fontFamily: "Gotham",
	},
	statsContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: 20,
		padding: 16,
		backgroundColor: "#F7FAFC",
		borderRadius: 12,
	},
	statItem: {
		alignItems: "center",
	},
	statNumber: {
		fontSize: 24,
		fontWeight: "600",
		color: "#2D3748",
		fontFamily: "Gotham",
	},
	statLabel: {
		fontSize: 12,
		color: "#718096",
		fontFamily: "Gotham",
	},
	dateSection: {
		marginBottom: 20,
	},
	dateText: {
		fontSize: 14,
		color: "#4A5568",
		fontFamily: "Gotham",
	},
	section: {
		marginTop: 20,
	},
	sectionTitle: {
		fontSize: 16,
		fontWeight: "600",
		color: "#2D3748",
		marginBottom: 12,
		fontFamily: "Gotham",
	},
	milestoneItem: {
		marginBottom: 12,
		padding: 16,
		backgroundColor: "#F7FAFC",
		borderRadius: 12,
		borderLeftWidth: 4,
		borderLeftColor: "#4299E1",
	},
	milestoneText: {
		fontSize: 14,
		color: "#2D3748",
		marginBottom: 6,
		fontFamily: "Gotham",
	},
	milestoneDate: {
		fontSize: 12,
		color: "#718096",
		fontFamily: "Gotham",
	},
	taskList: {
		marginTop: 20,
	},
	taskItem: {
		marginBottom: 12,
		padding: 16,
		backgroundColor: "#F7FAFC",
		borderRadius: 12,
	},
	taskHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	taskText: {
		fontSize: 14,
		color: "#2D3748",
		flex: 1,
		fontFamily: "Gotham",
	},
	taskStatus: {
		fontSize: 12,
		fontFamily: "Gotham",
	},
	mainTabsContainer: {
		flexGrow: 0,
		paddingVertical: 10,
		borderBottomWidth: 1,
		borderBottomColor: "#E2E8F0",
	},
	subTabsContainer: {
		flexGrow: 0,
		paddingVertical: 10,
		marginBottom: 10,
	},
	tabButton: {
		paddingHorizontal: 16,
		paddingVertical: 8,
		marginRight: 8,
		borderRadius: 20,
		backgroundColor: "#F7FAFC",
	},
	activeTabButton: {
		backgroundColor: APP_COLOURS.BG2,
	},
	tabButtonText: {
		color: "#718096",
		fontSize: 14,
		fontFamily: "Gotham",
	},
	activeTabText: {
		color: "#FFFFFF",
	},
	tabContent: {
		padding: 16,
	},
	tabContentContainer: {
		flex: 1,
	},
	noteCard: {
		backgroundColor: "#F7FAFC",
		padding: 16,
		borderRadius: 12,
		marginTop: 8,
	},
	noteText: {
		fontSize: 14,
		color: "#4A5568",
		fontFamily: "Gotham",
	},
	sentimentContainer: {
		flexDirection: "row",
		justifyContent: "space-around",
		marginTop: 16,
	},
	sentimentEmoji: {
		padding: 12,
		borderRadius: 12,
		backgroundColor: "#F7FAFC",
	},
	selectedSentiment: {
		backgroundColor: "#EBF8FF",
		borderWidth: 2,
		borderColor: APP_COLOURS.BG2,
	},
	emojiText: {
		fontSize: 24,
	},
	activitiesContainer: {
		marginTop: 16,
	},
	activitySection: {
		marginBottom: 24,
	},
	subSectionTitle: {
		fontSize: 14,
		fontWeight: "600",
		color: "#4A5568",
		marginBottom: 12,
		fontFamily: "Gotham",
	},
	activityItem: {
		backgroundColor: "#F7FAFC",
		padding: 12,
		borderRadius: 8,
		marginBottom: 8,
	},
	activityText: {
		fontSize: 14,
		color: "#2D3748",
		fontFamily: "Gotham",
	},
	activityDate: {
		fontSize: 12,
		color: "#718096",
		fontFamily: "Gotham",
		marginTop: 4,
	},
};

export default ProjectView;
