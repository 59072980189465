import React, { useState, useEffect } from "react";
import {
	StyleSheet,
	View,
	Text,
	TouchableOpacity,
	Modal,
	TextInput,
	Image,
	ScrollView,
} from "react-native";
import moment from "moment";
import {
	crudCreate,
	crudRead,
	crudUpdate,
	crudDelete,
	crudReadUnass,
} from "../../CRUDHELPER";
import { APP_COLOURS, TaskStatus, Avatars } from "../../APP_VARS";
import EditTask from "../Gantt/EditTask";
import AddTask from "./AddTask";
import ProgressBar from "./ProgressBar";
import Scroll from "quill/blots/scroll";

const ProjectMilestones = ({
	project,
	tasks,
	onMilestoneComplete,
	user_data,
	reloadData,
}) => {
	const [milestones, setMilestones] = useState([]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [selectedTask, setSelectedTask] = useState("");
	const [addProjectTask, setAddProjectTask] = useState(false);
	const [selectedMilestoneId, setSelectedMilestoneId] = useState(null);
	const [showunCategorisedTasks, setShowunCategorisedTasks] = useState(false);

	// Get unassigned tasks
	const unCategorisedTasks = tasks.filter(
		(task) => task.project_id === project.id && !task.milestone_id,
	);

	useEffect(() => {
		const fetchMilestones = async () => {
			try {
				const fetchedMilestones = await crudReadUnass(
					"projects_milestones",
					{
						field: "project_id",
						action: "==",
						value: project.id,
					},
					(status, data) => {
						if (status === 200) {
							setMilestones(data);
						}
					},
				);
			} catch (error) {
				console.error("Error fetching milestones:", error);
			}
		};

		if (project?.id) {
			fetchMilestones();
		}
	}, [project?.id]);

	const createMilestone = async (milestoneData) => {
		try {
			const newMilestone = await crudCreate(
				"projects_milestones",
				{
					...milestoneData,
					project_id: project.id,
					createdAt: new Date(),
					completed: false,
				},
				(status, data) => {
					if (status === 200) {
						setMilestones((prev) =>
							prev ? [...prev, data] : [data],
						);
					}
				},
			);
			return newMilestone;
		} catch (error) {
			console.error("Error creating milestone:", error);
			throw error;
		}
	};

	const handleAddTask = (milestoneId) => {
		setSelectedMilestoneId(milestoneId);
		setAddProjectTask(true);
	};

	const handleSelectTask = (task) => {
		setSelectedTask(task);
	};

	return (
		<View style={styles.container}>
			{selectedTask !== "" ? (
				<View style={styles.taskEditContainer}>
					<EditTask
						d={selectedTask}
						milestoneData={project}
						selectedProject={project}
						reloadData={() => {
							reloadData();
						}}
						close={() => {
							setSelectedTask("");
						}}
					/>
				</View>
			) : (
				<View style={styles.tasksContainer}>
					<ScrollView showsVerticalScrollIndicator={false}>
						<View
							style={{
								display: "flex",
								// flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
								backgroundColor: APP_COLOURS.OFFWHITE,
								borderRadius: 12,
								padding: 10,
								borderTopLeftRadius: 0,
								paddingTop: 30,
							}}>
							<Text style={styles.headerText}>
								Tasks & Milestones
							</Text>
							<TouchableOpacity
								style={styles.newMilestoneButton}
								onPress={() => setIsModalVisible(true)}>
								<Text style={styles.newMilestoneButtonText}>
									+ New Milestone
								</Text>
							</TouchableOpacity>
							<View style={styles.segmentedProgressBar}>
								{Object.entries(TaskStatus).map(
									([status, color]) => {
										const tasksWithStatus = tasks.filter(
											(t) =>
												t.project_id === project.id &&
												t.status === status,
										).length;
										const totalTasks = tasks.filter(
											(t) => t.project_id === project.id,
										).length;
										const width = totalTasks
											? (tasksWithStatus / totalTasks) *
											  100
											: 0;

										return width > 0 ? (
											<View
												key={status}
												style={[
													styles.progressSegment,
													{
														backgroundColor: color,
														width: `${width}%`,
													},
												]}
											/>
										) : null;
									},
								)}
							</View>
						</View>

						{unCategorisedTasks.length > 0 && (
							<View style={styles.milestoneCard}>
								<TouchableOpacity
									onPress={() =>
										setShowunCategorisedTasks(
											!showunCategorisedTasks,
										)
									}
									style={styles.milestoneHeader}>
									<View style={styles.headerLeft}>
										<Text style={styles.chevron}>
											{showunCategorisedTasks ? "▼" : "▶"}
										</Text>
										<Text
											style={[
												styles.milestoneTitle,
												{ color: APP_COLOURS.BG2 },
											]}>
											Uncategorised Tasks (
											{unCategorisedTasks.length})
										</Text>
									</View>
									<TouchableOpacity
										style={styles.newTaskButton}
										onPress={() => handleAddTask(null)}>
										<Text style={styles.newTaskButtonText}>
											+
										</Text>
									</TouchableOpacity>
								</TouchableOpacity>

								{showunCategorisedTasks && (
									<View style={styles.milestoneContent}>
										<View style={styles.tasksContainer}>
											{unCategorisedTasks.map(
												(task, index) => (
													<TouchableOpacity
														key={task.id || index}
														style={[
															styles.taskCard,
															{
																borderColor:
																	TaskStatus(
																		task.status,
																	),
															},
														]}
														onPress={() => {
															handleSelectTask(
																task,
															);
															// console.log(
															// 	"task",
															// 	task,
															// );
														}}>
														<View
															style={
																styles.taskContent
															}>
															<View
																style={
																	styles.taskLeftContent
																}>
																<Text
																	style={
																		styles.taskTitle
																	}>
																	<Text
																		style={{
																			fontWeight:
																				"bold",
																			color: APP_COLOURS.BLUE2,
																		}}>
																		{typeof task?.assignment !==
																			"undefined" &&
																		task.createdBy ===
																			user_data.id
																			? "You:"
																			: `@${
																					task?.assignment?.find(
																						(
																							a,
																						) =>
																							a.id ===
																							task.createdBy,
																					)
																						?.first_name ||
																					"Unknown"
																			  }:`}
																	</Text>{" "}
																	{task.title}
																</Text>
															</View>

															<View
																style={
																	styles.taskDateVisual
																}>
																<View
																	style={
																		styles.dateSquare
																	}>
																	<Text
																		style={
																			styles.dateSquareText
																		}>
																		{moment(
																			task.start_date,
																		).format(
																			"MMM DD",
																		)}
																	</Text>
																</View>
																<View
																	style={
																		styles.dotContainer
																	}>
																	<View
																		style={
																			styles.dot
																		}
																	/>
																	<View
																		style={
																			styles.dot
																		}
																	/>
																	<View
																		style={
																			styles.dot
																		}
																	/>
																</View>
																<View
																	style={
																		styles.dateSquare
																	}>
																	<Text
																		style={
																			styles.dateSquareText
																		}>
																		{moment(
																			task.end_date,
																		).format(
																			"MMM DD",
																		)}
																	</Text>
																</View>
															</View>

															<TaskStatusIndicator
																status={
																	task.status
																}
															/>
														</View>
													</TouchableOpacity>
												),
											)}
										</View>
									</View>
								)}
							</View>
						)}

						{milestones?.map((milestone, index) => {
							const milestoneTasks = tasks.filter(
								(task) => task.milestone_id === milestone.id,
							);

							return (
								<Milestone
									key={milestone.id || index}
									id={milestone.id}
									{...milestone}
									tasks={milestoneTasks}
									onComplete={() =>
										onMilestoneComplete(milestone.id)
									}
									onAddTask={handleAddTask}
									onSelectTask={handleSelectTask}
									reloadData={reloadData}
									user_data={user_data}
								/>
							);
						})}
						<View style={{ height: 200 }} />
					</ScrollView>
				</View>
			)}

			<NewMilestoneModal
				visible={isModalVisible}
				onClose={() => setIsModalVisible(false)}
				onSubmit={createMilestone}
				user_data={user_data}
			/>

			{addProjectTask && (
				<AddTask
					user_data={user_data}
					project_id={project.id}
					milestone_id={selectedMilestoneId}
					selectedProject={project}
					close={() => {
						setAddProjectTask(false);
						setSelectedMilestoneId(null);
					}}
					reloadData={() => {
						reloadData();
					}}
				/>
			)}
		</View>
	);
};

const Milestone = ({
	id,
	title,
	description,
	start_date,
	end_date,
	tasks,
	onComplete,
	onAddTask,
	onSelectTask,
	reloadData,
	user_data,
}) => {
	const [isExpanded, setIsExpanded] = useState(true);
	const [isCompleted, setIsCompleted] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [editedTitle, setEditedTitle] = useState(title);

	// Calculate progress
	const calculateProgress = () => {
		if (!tasks || tasks.length === 0) return 0;
		const completedTasks = tasks.filter((task) => task.completed).length;
		return (completedTasks / tasks.length) * 100;
	};

	// Add this function to sort tasks
	const sortedTasks = tasks?.sort((a, b) => {
		return new Date(a.start_date) - new Date(b.start_date);
	});

	useEffect(() => {
		// Check if all tasks are completed
		const allTasksCompleted = tasks?.every((task) => task.completed);
		if (allTasksCompleted && !isCompleted && tasks?.length > 0) {
			setIsCompleted(true);
			onComplete && onComplete();
		}
	}, [tasks]);

	const handleUpdateTitle = async () => {
		try {
			await crudUpdate(
				"projects_milestones",
				{ title: editedTitle, id: id },
				(status) => {
					if (status === 200) {
						setIsEditing(false);
						reloadData();
					}
				},
			);
		} catch (error) {
			console.error("Error updating milestone:", error);
		}
	};

	const getMilestoneDateRange = () => {
		if (!tasks || tasks.length === 0) return { start: null, end: null };

		const startDates = tasks.map((task) => moment(task.start_date));
		const endDates = tasks.map((task) => moment(task.end_date));

		return {
			start: moment.min(startDates),
			end: moment.max(endDates),
		};
	};

	return (
		<View style={styles.milestoneCard}>
			<TouchableOpacity
				onPress={() => setIsExpanded(!isExpanded)}
				style={styles.milestoneHeader}>
				<View style={styles.headerLeft}>
					<Text style={styles.chevron}>{isExpanded ? "▼" : "▶"}</Text>
					{isEditing ? (
						<View style={styles.editTitleContainer}>
							<TextInput
								style={styles.editTitleInput}
								value={editedTitle}
								onChangeText={setEditedTitle}
								autoFocus
							/>
							<TouchableOpacity
								style={styles.saveButton}
								onPress={handleUpdateTitle}>
								<Text style={styles.saveButtonText}>Save</Text>
							</TouchableOpacity>
						</View>
					) : (
						<TouchableOpacity onPress={() => setIsEditing(true)}>
							<Text style={styles.milestoneTitle}>{title}</Text>
						</TouchableOpacity>
					)}
				</View>

				{tasks && tasks.length > 0 && (
					<View style={[styles.taskDateVisual, { marginRight: 10 }]}>
						<View style={styles.dateSquare}>
							<Text style={styles.dateSquareText}>
								{getMilestoneDateRange().start.format("MMM DD")}
							</Text>
						</View>
						<View style={styles.dotContainer}>
							<View style={styles.dot} />
							<View style={styles.dot} />
							<View style={styles.dot} />
						</View>
						<View style={styles.dateSquare}>
							<Text style={styles.dateSquareText}>
								{getMilestoneDateRange().end.format("MMM DD")}
							</Text>
						</View>
					</View>
				)}

				<View style={styles.headerRight}>
					<Text style={styles.progressText}>{`${Math.round(
						calculateProgress(),
					)}%`}</Text>
					{isCompleted && <Text style={styles.checkmark}>✓</Text>}
				</View>
				<TouchableOpacity
					style={styles.newTaskButton}
					onPress={() => onAddTask(id)}>
					<Text style={styles.newTaskButtonText}>+</Text>
				</TouchableOpacity>
			</TouchableOpacity>

			{/* Progress Bar */}
			<View style={styles.progressBarContainer}>
				<View
					style={[
						styles.progressBar,
						{ width: `${calculateProgress()}%` },
					]}
				/>
			</View>

			{isExpanded && (
				<View style={styles.milestoneContent}>
					<View
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
						}}>
						<Text style={styles.description}>{description}</Text>
					</View>
					{/* <Text style={{}}>Assigned to you</Text> */}
					<View style={styles.dateContainer}>
						<Text style={styles.dateText}>
							{moment(start_date).format("MMM DD, YYYY")} -
						</Text>
						<Text style={styles.dateText}>
							{moment(end_date).format("MMM DD, YYYY")}
						</Text>
					</View>
					<View style={styles.tasksContainer}>
						{sortedTasks && sortedTasks.length > 0 ? (
							sortedTasks.map((task, index) => (
								<TouchableOpacity
									key={task.id || index}
									style={[
										styles.taskCard,
										{
											borderColor: TaskStatus(
												task.status,
											),
										},
									]}
									onPress={() => {
										onSelectTask(task);
										console.log("task", task);
									}}>
									<View style={styles.taskContent}>
										<View style={styles.taskLeftContent}>
											<Text style={styles.taskTitle}>
												<Text
													style={{
														fontWeight: "bold",
														color: APP_COLOURS.BLUE2,
													}}>
													{/* {task.createdBy} */}
													{task.createdBy ===
													user_data.id
														? "You:"
														: `@${
																task?.assignment?.find(
																	(a) =>
																		a.id ===
																		task.createdBy,
																)?.first_name ||
																"Unknown"
														  }:`}
												</Text>{" "}
												{task.title}
											</Text>
										</View>

										<View style={styles.avatarContainer}>
											{task.assignment &&
											task.assignment.length > 0 ? (
												<View style={styles.avatarRow}>
													{task.assignment
														.slice(0, 3)
														.map((ass, i) => (
															<View
																key={i}
																style={[
																	styles.avatarWrapper,
																	{
																		right:
																			i *
																			12,
																	},
																]}>
																<Image
																	source={
																		Avatars[
																			parseInt(
																				ass.avatar,
																			)
																		].pp
																	}
																	style={
																		styles.avatar
																	}
																/>
															</View>
														))}
													{task.assignment.length >
														3 && (
														<View
															style={[
																styles.avatarWrapper,
																styles.avatarCount,
																{ right: 36 },
															]}>
															<Text
																style={
																	styles.avatarCountText
																}>
																+
																{task.assignment
																	.length - 3}
															</Text>
														</View>
													)}
												</View>
											) : (
												<Text
													style={styles.noAssignment}>
													No Assignment
												</Text>
											)}
										</View>

										<View style={styles.taskDateVisual}>
											<View style={styles.dateSquare}>
												<Text
													style={
														styles.dateSquareText
													}>
													{moment(
														task.start_date,
													).format("MMM DD")}
												</Text>
											</View>
											<View style={styles.dotContainer}>
												<View style={styles.dot} />
												<View style={styles.dot} />
												<View style={styles.dot} />
											</View>
											<View style={styles.dateSquare}>
												<Text
													style={
														styles.dateSquareText
													}>
													{moment(
														task.end_date,
													).format("MMM DD")}
												</Text>
											</View>
										</View>

										<TaskStatusIndicator
											status={task.status}
										/>
									</View>
								</TouchableOpacity>
							))
						) : (
							<Text style={styles.noTasksText}>No tasks yet</Text>
						)}
					</View>

					{/* Moved Add Task button inside expanded view */}
				</View>
			)}
		</View>
	);
};

const NewMilestoneModal = ({ visible, onClose, onSubmit }) => {
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [start_date, setstart_date] = useState(new Date());
	const [end_date, setend_date] = useState(new Date());

	const handleSubmit = () => {
		onSubmit({
			title,
			description,
			start_date,
			end_date,
		});
		setTitle("");
		setDescription("");
		setstart_date(new Date());
		setend_date(new Date());
		onClose();
	};

	return (
		<Modal
			visible={visible}
			animationType='slide'
			transparent={true}
			onRequestClose={onClose}>
			<View style={styles.modalOverlay}>
				<View style={styles.modalContent}>
					<Text style={styles.modalTitle}>New Milestone</Text>

					<TextInput
						style={styles.input}
						placeholder='Milestone Title'
						value={title}
						onChangeText={setTitle}
					/>

					<TextInput
						style={[styles.input, styles.textArea]}
						placeholder='Description'
						value={description}
						onChangeText={setDescription}
						multiline
					/>

					{/* You might want to add a proper date picker here */}

					<View style={styles.modalButtons}>
						<TouchableOpacity
							style={styles.button}
							onPress={onClose}>
							<Text style={styles.buttonText}>Cancel</Text>
						</TouchableOpacity>
						<TouchableOpacity
							style={[styles.button, styles.submitButton]}
							onPress={handleSubmit}>
							<Text
								style={[
									styles.buttonText,
									styles.submitButtonText,
								]}>
								Create
							</Text>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		</Modal>
	);
};

const TaskStatusIndicator = ({ status }) => (
	<View
		style={{
			backgroundColor: TaskStatus(status),
			paddingHorizontal: 10,
			paddingVertical: 4,
			borderRadius: 12,
			width: 100,
			justifyContent: "center",
			alignItems: "center",
		}}>
		<Text
			style={{
				color: "white",
				fontSize: 12,
				fontWeight: "500",
			}}>
			{status || "In Progress"}
		</Text>
	</View>
);

const styles = StyleSheet.create({
	container: {
		flex: 1,
		// padding: 16,
		// backgroundColor: "#F7F9FC",
	},
	milestoneCard: {
		marginTop: 10,
		borderRadius: 12,
		overflow: "hidden",
		backgroundColor: APP_COLOURS.OFFWHITE,
		// shadowColor: "#000",
		// shadowOffset: {
		// 	width: 0,
		// 	height: 4,
		// },
		// shadowOpacity: 0.1,
		// shadowRadius: 8,
		// elevation: 3,
		borderWidth: 1,
		borderColor: "#E8EDF3",
	},
	milestoneHeader: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		padding: 16,
		backgroundColor: "white",
		opacity: 0.9,
		borderBottomWidth: 1,
		borderBottomColor: "#E8EDF3",
		// borderTopLeftRadius: 0,
		// backgroundColor: APP_COLOURS.,
	},
	headerLeft: {
		flexDirection: "row",
		alignItems: "center",
		flex: 1,
		marginRight: 10,
	},
	chevron: {
		fontSize: 20,
		marginRight: 12,
		color: APP_COLOURS.BLUE2,
	},
	checkmark: {
		fontSize: 18,
		color: "#22C55E",
	},
	milestoneTitle: {
		fontSize: 16,
		fontWeight: "600",
		color: "#1E293B",
	},
	milestoneContent: {
		padding: 16,
	},
	description: {
		marginBottom: 12,
		padding: 10,
		color: APP_COLOURS.BG2,
		lineHeight: 20,
	},
	dateContainer: {
		flexDirection: "row",
		justifyContent: "flex-start",
		marginBottom: 16,
		gap: 16,
	},
	dateText: {
		color: "#64748B",
		fontSize: 13,
		// backgroundColor: "#F1F5F9",
		paddingHorizontal: 10,
		paddingVertical: 4,
		borderRadius: 12,
	},
	tasksContainer: {
		flex: 1,
		gap: 8,
	},
	taskCard: {
		backgroundColor: "white",
		marginBottom: 8,
		borderRadius: 8,
		borderWidth: 1,
		borderColor: "#E8EDF3",
		// shadowColor: "#000",
		// shadowOffset: {
		// 	width: 0,
		// 	height: 2,
		// },
		// shadowOpacity: 0.05,
		// shadowRadius: 3,
		// elevation: 1,
	},
	taskContent: {
		padding: 14,
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	taskLeftContent: {
		flex: 1,
		marginRight: 10,
	},
	taskDates: {
		marginTop: 4,
	},
	taskDateText: {
		fontSize: 12,
		color: APP_COLOURS.BG2,
		fontStyle: "italic",
	},
	taskStatus: {
		fontSize: 12,
		color: "#666",
		marginTop: 4,
	},
	newMilestoneButton: {
		// backgroundColor: "white",
		padding: 14,
		borderRadius: 12,
		marginBottom: 20,
		alignItems: "center",
		borderWidth: 2,
		borderColor: APP_COLOURS.BLUE,
		borderStyle: "dashed",
		// opacity: 0.5,
	},
	newMilestoneButtonText: {
		color: APP_COLOURS.BLUE2,
		fontWeight: "600",
		fontSize: 15,
	},
	modalOverlay: {
		flex: 1,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		justifyContent: "center",
		alignItems: "center",
	},
	modalContent: {
		backgroundColor: "white",
		borderRadius: 16,
		padding: 24,
		width: "90%",
		maxWidth: 500,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 8,
		},
		shadowOpacity: 0.25,
		shadowRadius: 12,
		elevation: 5,
	},
	modalTitle: {
		fontSize: 20,
		fontWeight: "bold",
		marginBottom: 20,
		textAlign: "center",
	},
	input: {
		borderWidth: 1.5,
		borderColor: "#E8EDF3",
		borderRadius: 12,
		padding: 14,
		marginBottom: 16,
		fontSize: 15,
		backgroundColor: "#F8FAFC",
	},
	textArea: {
		height: 100,
		textAlignVertical: "top",
	},
	modalButtons: {
		flexDirection: "row",
		justifyContent: "flex-end",
		gap: 12,
		marginTop: 8,
	},
	button: {
		padding: 14,
		borderRadius: 12,
		minWidth: 120,
		alignItems: "center",
	},
	submitButton: {
		backgroundColor: APP_COLOURS.BLUE2,
		shadowColor: APP_COLOURS.BLUE2,
		shadowOffset: {
			width: 0,
			height: 4,
		},
		shadowOpacity: 0.2,
		shadowRadius: 5,
		elevation: 3,
	},
	buttonText: {
		fontSize: 16,
		color: "#007AFF",
	},
	submitButtonText: {
		color: "white",
	},
	taskEditContainer: {
		flex: 1,
	},
	newTaskButton: {
		flex: 1,
		maxWidth: 80,
		backgroundColor: APP_COLOURS.OFFWHITE,
		borderRadius: 25,
		paddingVertical: 5,
		paddingHorizontal: 5,
		marginLeft: 10,
		borderWidth: 1,
		borderColor: APP_COLOURS.BLUE2,
		borderStyle: "dashed",
	},
	newTaskButtonText: {
		color: APP_COLOURS.BLUE2,
		fontSize: 15,
		fontWeight: "600",
		textAlign: "center",
	},
	taskTitle: {
		fontSize: 14,
		fontWeight: "500",
		marginBottom: 4,
	},
	noTasksText: {
		color: "#666",
		fontStyle: "italic",
		textAlign: "center",
		padding: 8,
	},
	headerRight: {
		flexDirection: "row",
		alignItems: "center",
		gap: 8,
	},
	progressText: {
		fontSize: 12,
		color: "#666",
	},
	progressBarContainer: {
		height: 6,
		backgroundColor: "#F1F5F9",
		width: "100%",
		borderRadius: 3,
	},
	progressBar: {
		height: "100%",
		backgroundColor: APP_COLOURS.BLUE2,
		borderRadius: 3,
	},
	editTitleContainer: {
		flexDirection: "row",
		alignItems: "center",
		flex: 1,
	},
	editTitleInput: {
		flex: 1,
		borderWidth: 1,
		borderColor: APP_COLOURS.BLUE2,
		borderRadius: 4,
		padding: 4,
		marginRight: 8,
		backgroundColor: "white",
		fontSize: 16,
		fontWeight: "bold",
	},
	saveButton: {
		backgroundColor: APP_COLOURS.BLUE2,
		padding: 6,
		borderRadius: 4,
	},
	saveButtonText: {
		color: "white",
		fontSize: 12,
		fontWeight: "bold",
	},
	headerText: {
		color: APP_COLOURS.BG2,
		fontSize: 22,
		fontWeight: "900",
		marginBottom: 6,
	},
	progressBarWrapper: {
		width: "100%",
		marginVertical: 15,
		backgroundColor: "#FFF",
		padding: 15,
		borderRadius: 12,
	},
	segmentedProgressBar: {
		height: 8,
		backgroundColor: "#F1F5F9",
		borderRadius: 4,
		flexDirection: "row",
		overflow: "hidden",
	},
	progressSegment: {
		height: "100%",
	},
	statusLegend: {
		flexDirection: "row",
		flexWrap: "wrap",
		marginTop: 8,
		gap: 12,
	},
	legendItem: {
		flexDirection: "row",
		alignItems: "center",
		marginRight: 12,
	},
	legendDot: {
		width: 8,
		height: 8,
		borderRadius: 4,
		marginRight: 6,
	},
	legendText: {
		fontSize: 12,
		color: APP_COLOURS.BG2,
	},
	taskDateVisual: {
		flexDirection: "row",
		alignItems: "center",
		marginLeft: 10,
		marginRight: 10,
	},
	dateSquare: {
		padding: 6,
		backgroundColor: APP_COLOURS.OFFWHITE,
		borderRadius: 6,
		borderWidth: 1,
		borderColor: "#E8EDF3",
	},
	dateSquareText: {
		fontSize: 11,
		color: APP_COLOURS.BG2,
		fontWeight: "500",
	},
	dotContainer: {
		flexDirection: "row",
		alignItems: "center",
		marginHorizontal: 8,
	},
	dot: {
		width: 4,
		height: 4,
		borderRadius: 2,
		backgroundColor: "#E8EDF3",
		marginHorizontal: 2,
	},
	avatarContainer: {
		marginHorizontal: 10,
	},
	avatarRow: {
		flexDirection: "row",
		alignItems: "center",
		height: 24,
	},
	avatarWrapper: {
		width: 24,
		height: 24,
		borderRadius: 12,
		backgroundColor: "#FFF",
		borderWidth: 2,
		borderColor: "#FFF",
		position: "relative",
		zIndex: 1,
		shadowColor: "#6494B7",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.23,
		shadowRadius: 2.65,
		elevation: 4,
	},
	avatar: {
		width: 20,
		height: 20,
		borderRadius: 10,
		resizeMode: "contain",
	},
	avatarCount: {
		backgroundColor: "#252525",
		justifyContent: "center",
		alignItems: "center",
	},
	avatarCountText: {
		color: "#FFF",
		fontSize: 10,
		fontWeight: "500",
	},
	noAssignment: {
		fontSize: 12,
		color: APP_COLOURS.BG2,
		fontStyle: "italic",
	},
});

export default ProjectMilestones;
