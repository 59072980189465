import React, { useState, useEffect } from "react";
import Editor from "./Editor/NotesEditor";
import {
	View,
	Text,
	TouchableOpacity,
	ScrollView,
	TextInput,
	Alert,
	Modal,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import {
	crudCreateWithHtml,
	crudRead,
	deleteProposalPage,
} from "../../CRUDHELPER";
import moment from "moment/moment";

const Notes = ({ user_data, closeNotes }) => {
	const [notes, setNotes] = useState([]);
	const [selectedNoteIndex, setSelectedNoteIndex] = useState(null);
	const [editingTitle, setEditingTitle] = useState(false);
	const [deleteModalVisible, setDeleteModalVisible] = useState(false);
	const [noteToDelete, setNoteToDelete] = useState(null);
	const [saveStatus, setSaveStatus] = useState("");

	useEffect(() => {
		fetchNotes();
	}, []);

	const fetchNotes = () => {
		crudRead("personal_notes", "", (status, data) => {
			console.log(data);
			if (status === 200 && data) {
				const formattedNotes = data.map((note) => ({
					id: note.presentation_id,
					title: note.title || `Note ${note.presentation_id}`,
					content: note.html || "",
					htmlData: note,
					last_updated: note.last_updated,
				}));
				setNotes(formattedNotes);
			}
		});
	};

	const handleCreateNote = () => {
		const newNote = {
			id: Date.now(),
			title: `Note ${notes.length + 1}`,
			content: "",
			htmlData: null,
		};
		setNotes([...notes, newNote]);
		setSelectedNoteIndex(notes.length);
	};

	const handleNoteChange = (content) => {
		if (selectedNoteIndex !== null) {
			const updatedNotes = [...notes];
			updatedNotes[selectedNoteIndex] = {
				...updatedNotes[selectedNoteIndex],
				content,
			};
			setNotes(updatedNotes);
		}
	};

	const handleSaveNote = (html) => {
		const currentNote = notes[selectedNoteIndex];

		setSaveStatus("Saving...");

		crudCreateWithHtml(
			"personal_notes",
			{
				presentation_id: currentNote.id,
				title: currentNote.title,
				html: html,
				_id: currentNote.htmlData?._id || "",
				assignment_ids: [user_data?.id],
				assignment: [user_data],
			},
			async (status, data) => {
				const updatedNotes = [...notes];
				updatedNotes[selectedNoteIndex] = {
					...updatedNotes[selectedNoteIndex],
					htmlData: data,
					last_updated: moment().toISOString(),
				};
				setNotes(updatedNotes);
				fetchNotes();

				setSaveStatus("Saved!");
				setTimeout(() => {
					setSaveStatus("");
					setSelectedNoteIndex(0);
				}, 2000);
			},
		);
	};

	const handleDeleteNote = (note) => {
		// console.log("note", note.htmlData.htmlData);
		// return;
		setNoteToDelete(note);
		setDeleteModalVisible(true);
	};

	const confirmDelete = () => {
		if (noteToDelete) {
			// console.log("noteToDelete", noteToDelete);
			// return;
			let obj = {
				...noteToDelete,
				htmlFileName: noteToDelete.htmlData.htmlData.htmlFileName,
				_id: noteToDelete.htmlData.htmlData._id,
			};
			// console.log(obj);
			// return;
			deleteProposalPage("personal_notes", obj, (status) => {
				if (status === 200) {
					const updatedNotes = notes.filter(
						(note) => note.id !== noteToDelete.id,
					);
					setNotes(updatedNotes);
					if (selectedNoteIndex !== null) {
						setSelectedNoteIndex(null);
					}
				}
			});
		}
		setDeleteModalVisible(false);
		setNoteToDelete(null);
	};

	const handleTitleChange = (newTitle, index) => {
		const updatedNotes = [...notes];
		updatedNotes[index] = {
			...updatedNotes[index],
			title: newTitle,
		};
		setNotes(updatedNotes);
	};

	return (
		<Modal visible={true} transparent={true} animationType='fade'>
			<View
				style={{
					width: window.innerWidth,
					height: window.innerHeight,
					backgroundColor: "rgba(0, 0, 0, 0.75)",

					justifyContent: "flex-end",
				}}>
				<View
					style={{
						width: "100%",
						maxWidth: 800,
						alignSelf: "flex-end",
						display: "flex",
						flexDirection: "row",
						gap: 16,
						height: "100%",
					}}>
					{/* Notes sidebar */}
					<View
						style={{
							width: 300,
							padding: 16,
							paddingRight: 30,
							left: 30,
							backgroundColor: "#FFF",
							borderRadius: 8,
							shadowColor: "#000",
							shadowOffset: { width: 0, height: 2 },
							shadowOpacity: 0.1,
							shadowRadius: 4,
							elevation: 3,
						}}>
						<View
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
								marginBottom: 16,
							}}>
							<TouchableOpacity
								onPress={closeNotes}
								style={{
									padding: 8,
									borderRadius: 6,
								}}>
								<Text
									style={{
										color: APP_COLOURS.BG2,
										fontSize: 18,
									}}>
									×
								</Text>
							</TouchableOpacity>
							<Text
								style={{
									fontSize: 18,
									fontWeight: "600",
									color: APP_COLOURS.BG2,
								}}>
								Notes
							</Text>
							<TouchableOpacity
								onPress={handleCreateNote}
								style={{
									backgroundColor: APP_COLOURS.BG2,
									paddingVertical: 8,
									paddingHorizontal: 12,
									borderRadius: 6,
									flexDirection: "row",
									alignItems: "center",
								}}>
								<Text
									style={{
										color: "#FFF",
										fontSize: 14,
										marginLeft: 4,
									}}>
									+ Note
								</Text>
							</TouchableOpacity>
						</View>
						<ScrollView>
							{notes.map((note, index) => (
								<TouchableOpacity
									key={note.id}
									onPress={() => setSelectedNoteIndex(index)}
									style={{
										padding: 12,
										marginBottom: 8,
										backgroundColor:
											selectedNoteIndex === index
												? APP_COLOURS.OFFWHITE
												: "transparent",

										borderRadius: 6,
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
									}}>
									<Text
										numberOfLines={1}
										style={{
											color: APP_COLOURS.BG2,
											fontSize: 14,
											flex: 1,
										}}>
										{note.title}
									</Text>
									<TouchableOpacity
										onPress={() => handleDeleteNote(note)}
										style={{
											padding: 4,
										}}>
										<Text
											style={{
												color: APP_COLOURS.RED2,
												fontSize: 14,
											}}>
											×
										</Text>
									</TouchableOpacity>
								</TouchableOpacity>
							))}
						</ScrollView>
					</View>

					{/* Editor area */}
					<View
						style={{
							flex: 1,

							backgroundColor: APP_COLOURS.OFFWHITE,
							borderRadius: 8,
						}}>
						{selectedNoteIndex !== null ? (
							<View style={{ flex: 1 }}>
								<ScrollView>
									<View
										style={{
											flex: 1,
											maxHeight: window.innerHeight * 0.8,
										}}>
										<View
											style={{
												marginBottom: 16,
												padding: 16,
												flexDirection: "row",
												justifyContent: "space-between",
												alignItems: "center",
											}}>
											<View>
												{editingTitle ? (
													<TextInput
														value={
															notes[
																selectedNoteIndex
															].title
														}
														onChangeText={(text) =>
															handleTitleChange(
																text,
																selectedNoteIndex,
															)
														}
														onBlur={() =>
															setEditingTitle(
																false,
															)
														}
														autoFocus
														style={{
															fontSize: 20,
															fontWeight: "600",
															color: APP_COLOURS.BG2,
															padding: 8,
															backgroundColor:
																"#FFF",
															borderRadius: 4,
														}}
													/>
												) : (
													<TouchableOpacity
														onPress={() =>
															setEditingTitle(
																true,
															)
														}>
														<Text
															style={{
																fontSize: 20,
																fontWeight:
																	"600",
																color: APP_COLOURS.BG2,
															}}>
															{
																notes[
																	selectedNoteIndex
																].title
															}
														</Text>
													</TouchableOpacity>
												)}
											</View>

											{saveStatus && (
												<Text
													style={{
														color:
															saveStatus ===
															"Saved!"
																? "#4CAF50"
																: APP_COLOURS.BG2,
														fontSize: 14,
														marginLeft: 10,
													}}>
													{saveStatus}
												</Text>
											)}
										</View>
										<Editor
											initialValue={
												notes[selectedNoteIndex].content
											}
											onChange={handleNoteChange}
											presentation_id={
												notes[selectedNoteIndex].id
											}
											pageData={
												notes[selectedNoteIndex]
													.htmlData
											}
											reloadData={handleSaveNote}
											lockContent={false}
										/>
									</View>
								</ScrollView>
							</View>
						) : (
							<View
								style={{
									height: "100%",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}>
								<Text
									style={{
										color: "#666",
										fontSize: 14,
									}}>
									Select a note or create a new one to start
									editing
								</Text>
							</View>
						)}
					</View>

					{/* Add Delete Confirmation Modal */}
					<Modal
						visible={deleteModalVisible}
						transparent={true}
						animationType='fade'>
						<View
							style={{
								flex: 1,
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "rgba(0, 0, 0, 0.5)",
							}}>
							<View
								style={{
									backgroundColor: "white",
									padding: 20,
									borderRadius: 8,
									width: 300,
									shadowColor: "#000",
									shadowOffset: { width: 0, height: 2 },
									shadowOpacity: 0.25,
									shadowRadius: 4,
									elevation: 5,
								}}>
								<Text
									style={{
										fontSize: 18,
										fontWeight: "600",
										marginBottom: 16,
										color: APP_COLOURS.BG2,
									}}>
									Delete Note
								</Text>
								<Text
									style={{
										marginBottom: 20,
										color: APP_COLOURS.BG2,
									}}>
									Are you sure you want to delete this note?
								</Text>
								<View
									style={{
										flexDirection: "row",
										justifyContent: "flex-end",
										gap: 12,
									}}>
									<TouchableOpacity
										onPress={() =>
											setDeleteModalVisible(false)
										}
										style={{
											padding: 8,
											paddingHorizontal: 16,
											borderRadius: 6,
											backgroundColor: "#f0f0f0",
										}}>
										<Text>Cancel</Text>
									</TouchableOpacity>
									<TouchableOpacity
										onPress={confirmDelete}
										style={{
											padding: 8,
											paddingHorizontal: 16,
											borderRadius: 6,
											backgroundColor: APP_COLOURS.RED2,
										}}>
										<Text style={{ color: "white" }}>
											Delete
										</Text>
									</TouchableOpacity>
								</View>
							</View>
						</View>
					</Modal>
				</View>
			</View>
		</Modal>
	);
};

export default Notes;
