import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	Modal,
} from "react-native";
import { APP_COLOURS, Avatars, PROJECTSTATUS } from "../../APP_VARS";
import moment from "moment";
import {
	crudRead,
	crudReadAll,
	crudCreate,
	crudDelete,
	crudUpdate,
	crudGetUsers,
	crudSendEmail,
	crudReadUnass,
	sendDiscordMessage,
	crudSendUserIdEmail,
	crudCreateGoogleDrive,
	crudListGoogleDriveFolderContents,
} from "../../CRUDHELPER";
import AutoHeightImage from "../AutoHeightImageComp";
import { ActivityIndicator, ScrollView } from "react-native-web";
import { googleDriveIcon } from "./googleDriveIcon";
function uuid() {
	return "xxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function isOdd(num) {
	return num % 2;
}

export default class ProjectDocuments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props,
			loading: true,
			showEdit: false,
			documents: [],
			driveFiles: [],
			loadingDriveFiles: false,
			showExportMenu: null,

			crudDocumentName: "project_documents",

			driveURL: "",
			driveTITLE: "",

			showAddDocument: false,
			creatingDriveFolder: false,
		};

		this.driveTITLE = React.createRef();
		this.driveURL = React.createRef();
	}

	componentDidMount() {
		this.getAllProjectDocuments();
		if (this.state.selectedProject?.isGoogleDriveFolderActive) {
			this.fetchGoogleDriveFiles();
		}
	}

	getAllProjectDocuments = () => {
		if (this.state.user_data.id === "") {
			return;
		}
		this.setState({
			loading: true,
			documents: [],
		});

		crudReadUnass(
			this.state.crudDocumentName,
			{
				field: "project_id",
				action: "==",
				value: this.props.project_id,
			},
			async (status, d) => {
				console.log("messages", status, d);
				if (status === 200) {
					await this.setState({
						documents: d,
						loading: false,
					});
				}
			},
		);
	};

	fetchGoogleDriveFiles = async () => {
		if (!this.state.selectedProject?.isGoogleDriveFolderActive) return;

		this.setState({ loadingDriveFiles: true });
		try {
			const folderData = this.state.selectedProject.googleDriveFolder;
			crudListGoogleDriveFolderContents(folderData, (status, data) => {
				if (status === 200) {
					// console.log(data);
					this.setState({
						driveFiles: data,
						loadingDriveFiles: false,
					});
				}
			});
		} catch (error) {
			console.error("Error fetching drive files:", error);
			this.setState({ loadingDriveFiles: false });
		}
	};

	handleCreateDocument = async () => {
		let obj = {
			project_id: this.state.selectedProject.id,
			title: this.state.driveTITLE,
			url: this.state.driveURL,
			date_created: moment().toISOString(),
			created_by: `${this.state.user_data.first_name} ${this.state.user_data.last_name}`,
		};
		console.log(obj);

		let user_data = JSON.parse(localStorage.getItem("user_data"));

		const mpmgh = await this.state.selectedProject.assignment_ids.forEach(
			async (id) => {
				await sendDiscordMessage(
					`${user_data.first_name} ${user_data.last_name}`,
					`### *${this.state.selectedProject?.brand?.title} - ${this.state.selectedProject?.title}*
[Document Upload]
> ${obj.title}
> ${obj.url}

- Project: ${window.location.origin}/home/projects?pid=${this.state.selectedProject.id}
`,
					`${window.location.origin}${Avatars[user_data.avatar].pp}`,
					id,
				);
			},
		);

		const mapThrough = await this.props.selectedProject.assignment.forEach(
			async (e) => {
				await crudSendEmail(
					"SKrum  <skrum@tetrice.co.za>",
					e.username,
					`[Document Upload]: ${this.props.selectedProject.title}`,
					`Project document update: <p>${this.props.selectedProject.title}</p> <br> <p> ${obj.title}</p>
				<p> ${obj.url}</p>
				<p>${window.location.origin}/home/projects?pid=${this.props.selectedProject.id}</p>
				`,
				);
			},
		);

		crudCreate(this.state.crudDocumentName, obj, () => {
			this.driveTITLE.clear();
			this.driveURL.clear();
			this.getAllProjectDocuments();
			this.setState({
				showAddDocument: false,
			});
		});
	};

	handleCreateGoogleDriveFolder = async () => {
		this.setState({ creatingDriveFolder: true });

		try {
			const folderData = {
				projectId: this.state.selectedProject.id,
				projectTitle: this.state.selectedProject.title,
				brandTitle:
					typeof this.state.selectedProject?.brand?.title !==
					"undefined"
						? this.state.selectedProject?.brand?.title
						: "General",
			};

			await crudCreateGoogleDrive(folderData, async (status, data) => {
				console.log(status, data);
				if (status === 200) {
					await crudUpdate(
						"projects",
						{
							...this.state.selectedProject,
							googleDriveFolder: data,
							isGoogleDriveFolderActive: true,
						},
						() => {
							this.props.reloadData();
							// Update local state to reflect changes
							this.setState((prevState) => ({
								selectedProject: {
									...prevState.selectedProject,
									googleDriveFolder: data,
									isGoogleDriveFolderActive: true,
								},
							}));
						},
					);
				}
			});
		} catch (error) {
			console.error("Error creating Google Drive folder:", error);
		} finally {
			this.setState({ creatingDriveFolder: false });
		}
	};

	handleExport = (exportLink, fileName, format) => {
		window.open(exportLink, "_blank");
	};

	render() {
		return (
			<View
				style={{
					flex: 1,
					padding: 5,
					// borderWidth: 0.5,
					// borderColor: "#797979",
					borderRadius: 15,
					paddingBottom: 20,
				}}>
				<View
					style={{
						flexDirection: "row",

						borderRadius: 15,
						overflow: "hidden",
						alignItems: "center",
					}}>
					<View
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
							justifyContent: "space-between",
							alignItems: "center",
							// backgroundColor: APP_COLOURS.OFFWHITE,
							borderRadius: 12,
							padding: 10,
							borderTopLeftRadius: 0,
							paddingTop: 30,
						}}>
						<Text
							style={{
								color: APP_COLOURS.BG2,
								fontSize: 22,
								fontWeight: "900",
								marginBottom: 6,
							}}>
							Google drive documents
						</Text>
						{this.state.selectedProject
							.isGoogleDriveFolderActive ? (
							<TouchableOpacity
								onPress={() => {
									window.open(
										`https://drive.google.com/drive/folders/${this.state.selectedProject.googleDriveFolder.id}?usp=sharing`,
										"_blank",
									);
								}}>
								<View
									style={{
										borderRadius: 15,
										overflow: "hidden",
										alignItems: "center",
										justifyContent: "center",
										backgroundColor: "#FFF",
										padding: 5,
										borderRadius: 15,
									}}>
									<AutoHeightImage
										width={35}
										source={{
											uri: googleDriveIcon,
										}}
										style={{
											borderRadius: 10,
											overflow: "hidden",
										}}
									/>
									<Text style={{ color: APP_COLOURS.BG2 }}>
										{
											this.state.selectedProject
												.googleDriveFolder.name
										}
									</Text>
								</View>
							</TouchableOpacity>
						) : (
							<TouchableOpacity
								onPress={() => {
									this.handleCreateGoogleDriveFolder();
								}}>
								<View
									style={{
										borderRadius: 15,
										overflow: "hidden",
										alignItems: "center",
										justifyContent: "center",
										backgroundColor: APP_COLOURS.BG2,
										padding: 10,
										opacity: this.state.creatingDriveFolder
											? 0.5
											: 0.8,
										flexDirection: "row",
										alignItems: "center",
									}}>
									{this.state.creatingDriveFolder ? (
										<ActivityIndicator
											color='#FFF'
											style={{ marginRight: 10 }}
										/>
									) : (
										<AutoHeightImage
											width={35}
											source={{
												uri: googleDriveIcon,
											}}
											style={{
												borderRadius: 10,
												overflow: "hidden",
											}}
										/>
									)}
									<Text
										style={{
											color: "#FFF",
											fontSize: 13,
											marginLeft: 5,
										}}>
										{this.state.creatingDriveFolder
											? "Creating Folder..."
											: "Create Google Drive Folder"}
									</Text>
								</View>
							</TouchableOpacity>
						)}
					</View>

					<TouchableOpacity
						onPress={() => {
							// this.deleteProject(d.id);
							this.setState({
								showEdit: !this.state.showEdit,
							});
						}}
						style={{
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							marginLeft: 5,
							marginRight: 10,
						}}>
						<Text style={{ color: APP_COLOURS.BLUE2 }}>Edit</Text>
					</TouchableOpacity>
				</View>
				<View
					style={{
						flex: 1,
						padding: 5,
						// backgroundColor: "#E8E8E8",
						backgroundColor: "#F1F5FA",
						borderRadius: 10,
					}}>
					<ScrollView>
						{this.state.loading ? (
							<View style={{ width: "100%" }}>
								<ActivityIndicator
									color={APP_COLOURS.PRIMARY}
								/>
							</View>
						) : null}

						{this.state.documents.map((f, i) => (
							<View
								key={`item_${f.id}`}
								onMouseEnter={() =>
									this.setState({ hover: f.id })
								}
								onMouseLeave={() =>
									this.setState({ hover: "" })
								}
								style={{
									backgroundColor:
										this.state.hover === f.id
											? APP_COLOURS.WHITE_TRANSPARENT9
											: "#FFF",
									borderBottomColor: "#E8E8E8",
									borderBottomWidth: 0.5,
									borderRadius: 9,
									marginBottom: 5,
									maxWidth: 550,
									width: "100%",
									alignSelf: "center",
								}}>
								<TouchableOpacity
									style={{}}
									onPress={() => {
										window.open(f.url, "_blank");
									}}>
									<View
										style={{
											marginLeft: 5,
											marginVertical: 5,
											padding: 5,
											flexDirection: "row",
											alignItems: "center",
										}}>
										{this.state.showEdit ? (
											<TouchableOpacity
												onPress={async () => {
													let user_data = JSON.parse(
														localStorage.getItem(
															"user_data",
														),
													);

													// 												sendDiscordMessage(
													// 													`${user_data.first_name} ${user_data.last_name}`,
													// 													`[Document Delete]
													// - ${f.title}
													// - ${this.props.project_id}`,
													// 													`${window.location.origin}${
													// 														Avatars[
													// 															user_data.avatar
													// 														].pp
													// 													}`,
													// 													"0QPZYrY6zpAnJXqX9gQl",
													// 												);

													const mpmgh =
														await this.state.selectedProject.assignment_ids.forEach(
															async (id) => {
																await sendDiscordMessage(
																	`${user_data.first_name} ${user_data.last_name}`,
																	`### *${this.state.selectedProject.brand.title} - ${this.state.selectedProject.title}*
[Document DELETE]
> ${f.title}
`,
																	`${
																		window
																			.location
																			.origin
																	}${
																		Avatars[
																			user_data
																				.avatar
																		].pp
																	}`,
																	id,
																);
															},
														);

													crudDelete(
														this.state
															.crudDocumentName,
														f,
														() => {
															this.getAllProjectDocuments();
															this.setState({
																showEdit: false,
															});
														},
													);
												}}
												style={{
													flexDirection: "row",
													justifyContent: "center",
													alignItems: "center",
													width: 35,
													height: 35,

													marginRight: 5,
												}}>
												<Image
													source={require("../../../assets/images/bin.png")}
													style={{
														width: 35,
														height: 35,
														resizeMode: "contain",
														marginRight: 5,
													}}
												/>
											</TouchableOpacity>
										) : (
											<AutoHeightImage
												width={20}
												// source={require("../../../assets/images/icons/document.png")}
												source={{
													uri: googleDriveIcon,
												}}
											/>
										)}

										<Text
											style={{
												color: "#000",
												fontSize: 14,
												fontWeight: "500",
												marginLeft: 10,
											}}>
											{f.title}
										</Text>
									</View>
								</TouchableOpacity>
							</View>
						))}
						{this.state.selectedProject
							.isGoogleDriveFolderActive && (
							<View
								style={{
									maxWidth: 550,
									width: "100%",
									alignSelf: "center",
								}}>
								{this.state.driveFiles.map((file, index) => (
									<View key={file.id}>
										<TouchableOpacity
											onPress={() =>
												window.open(
													file.webViewLink,
													"_blank",
												)
											}
											style={{
												flexDirection: "row",
												alignItems: "center",
												padding: 8,
												backgroundColor: isOdd(index)
													? "#F8F9FA"
													: "#FFF",
												borderRadius: 5,
												marginBottom: 4,
											}}>
											<AutoHeightImage
												width={20}
												source={{
													uri:
														file.iconLink ||
														googleDriveIcon,
												}}
												style={{
													marginRight: 10,
												}}
											/>
											<Text
												style={{
													flex: 1,
													color: APP_COLOURS.BG2,
													fontSize: 14,
												}}>
												{file.name}
											</Text>
											{file.exportLinks && (
												<TouchableOpacity
													onPress={() =>
														this.setState({
															showExportMenu:
																this.state
																	.showExportMenu ===
																file.id
																	? null
																	: file.id,
														})
													}
													style={{
														padding: 5,
														backgroundColor:
															APP_COLOURS.BLUE2,
														borderRadius: 5,
														marginLeft: 10,
													}}>
													<Text
														style={{
															color: "#FFF",
															fontSize: 12,
														}}>
														Export
													</Text>
												</TouchableOpacity>
											)}
										</TouchableOpacity>

										{this.state.showExportMenu ===
											file.id &&
											file.exportLinks && (
												<View
													style={{
														backgroundColor: "#FFF",
														borderRadius: 5,
														padding: 5,
														marginLeft: 30,
														marginBottom: 5,
														shadowColor: "#000",
														shadowOffset: {
															width: 0,
															height: 2,
														},
														shadowOpacity: 0.25,
														shadowRadius: 3.84,
														elevation: 5,
													}}>
													{Object.entries(
														file.exportLinks,
													).map(([format, link]) => (
														<TouchableOpacity
															key={format}
															onPress={() =>
																this.handleExport(
																	link,
																	file.name,
																	format,
																)
															}
															style={{
																padding: 8,
																borderBottomWidth: 0.5,
																borderBottomColor:
																	"#E8E8E8",
															}}>
															<Text
																style={{
																	color: APP_COLOURS.BG2,
																	fontSize: 12,
																}}>
																{format
																	.split("/")
																	.pop()}
															</Text>
														</TouchableOpacity>
													))}
												</View>
											)}
									</View>
								))}
							</View>
						)}
					</ScrollView>
				</View>

				{this.state.showAddDocument ? (
					<Modal
						animationType='fade'
						transparent={true}
						visible={true}>
						<View
							style={{
								flex: 1,
								justifyContent: "flex-start",
								alignItems: "center",
								backgroundColor: "rgba(52, 52, 52, 0.6)",
							}}>
							<View
								style={{
									flex: 1,
									justifyContent: "center",
									alignItems: "center",
								}}>
								<View
									style={{
										// flex: 1,
										width: 300,
										// height: 250,
										backgroundColor: "#FFF",
										justifyContent: "center",
										// alignItems: "center",
										borderRadius: 15,
										padding: 10,
									}}>
									<View
										style={{
											paddingLeft: 10,
											flexDirection: "row",
										}}>
										<TouchableOpacity
											onPress={async () => {
												this.setState({
													showAddDocument: false,
												});
											}}>
											<Image
												style={{
													width: 24,
													height: 24,
													resizeMode: "contain",
													marginRight: 10,
												}}
												source={require("../../../assets/images/close.png")}
											/>
										</TouchableOpacity>
										<Text
											style={{
												flex: 1,
												color: APP_COLOURS.BG2,
												opacity: 0.8,
												fontSize: 15,
												fontWeight: "700",
											}}>
											Add document
										</Text>
									</View>
									<View
										style={{
											borderWidth: 0.5,
											borderColor: "#797979",
											borderRadius: 15,
											marginTop: 5,

											maxWidth: 850,
											width: "100%",
											marginHorizontal: "auto",
											padding: 5,
										}}>
										<View
											style={{
												width: "100%",
												maxWidth: "100%",
												flexDirection: "row",
												// marginTop: 5,
												// justifyContent: "center",
												// alignItems: "center",
											}}>
											<TextInput
												ref={(ref) =>
													(this.driveURL = ref)
												}
												// ref={this.driveURL}
												style={{
													width: "80%",
													maxHeight: 300,
													fontSize: 16,
													color: APP_COLOURS.BLUE,
													marginRight: 10,
													padding: 5,
													// borderBottomWidth: 0.5,
													// borderBottomColor: "#797979",
													marginTop: 3,
												}}
												numberOfLines={1}
												placeholderTextColor={"#797979"}
												clearButtonMode='while-editing'
												autoCorrect={true}
												placeholder={`1) Paste URL to document`}
												onChangeText={(d) => {
													this.setState({
														driveURL: d,
													});
												}}
											/>
										</View>
										<View
											style={{
												width: "100%",
												maxWidth: "100%",
												flexDirection: "row",
												marginTop: 5,
												// justifyContent: "center",
												// alignItems: "center",
												justifyContent: "space-between",
											}}>
											<TextInput
												ref={(ref) =>
													(this.driveTITLE = ref)
												}
												// ref={this.driveTITLE}
												style={{
													width: "80%",
													maxHeight: 300,
													fontSize: 16,
													color: APP_COLOURS.PRIMARY,
													marginRight: 10,
													padding: 5,
													fontWeight: "500",
												}}
												numberOfLines={1}
												placeholderTextColor={"#797979"}
												clearButtonMode='while-editing'
												autoCorrect={true}
												placeholder={`2) Title the document`}
												onChangeText={(d) => {
													this.setState({
														driveTITLE: d,
													});
												}}
											/>
										</View>
										{/* <Text>{this.state.driveTITLE}</Text> */}
										<TouchableOpacity
											disabled={
												this.state.driveTITLE === "" ||
												this.state.driveURL === ""
											}
											onPress={this.handleCreateDocument}>
											<View
												style={{
													// width: 90,
													width: "100%",
													height: 40,
													marginTop: 5,
													justifyContent: "center",
													alignItems: "center",
													backgroundColor:
														APP_COLOURS.GREEN,
													borderRadius: 15,

													opacity:
														this.state
															.driveTITLE !==
															"" &&
														this.state.driveURL !==
															""
															? 1
															: 0.6,
												}}>
												<Text
													style={{
														color: "#FFF",
														fontSize: 13,
													}}>
													Save
												</Text>
											</View>
										</TouchableOpacity>
									</View>
								</View>
							</View>
						</View>
					</Modal>
				) : (
					<TouchableOpacity
						onPress={async () => {
							this.setState({
								showAddDocument: !this.state.showAddDocument,
							});
						}}>
						<View
							style={{
								maxWidth: 550,
								width: "100%",
								alignSelf: "center",
								height: 40,
								marginTop: 5,
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: APP_COLOURS.GREEN,
								borderRadius: 15,
							}}>
							<Text
								style={{
									color: "#FFF",
									fontSize: 13,
								}}>
								+ External Document
							</Text>
						</View>
					</TouchableOpacity>
				)}
			</View>
		);
	}
}
